import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import parse from 'html-react-parser';
import { classNames } from "../../_helpers";
import { MainHeader } from "../../_ui/MainHeader";
import { Property } from "../../types/graphql/Property";
import { PropertyAnswer } from "../../types/graphql/PropertyAnswer";
import { EditUserForm } from "../EditUser/EditUserForm";
import { useMyself } from "../UserContext";
import { EditMembershipFieldView } from "./EditMembershipFieldView";

type PropTypes = {
  title: string,
  description: string,
  status: string,
  answers: PropertyAnswer[]
  fields: Property[]
  submitAction?: () => void
  formData: any
  handleCancelMembership: () => void
  changeFormFieldValue: (propertyID: string, value: boolean | string | string[]) => void
  hideHeader?: boolean
  hidePrivate?: boolean
  includeStatus?: boolean
  submitOnClick?: boolean
  readonly?: boolean
  showInfotext?: boolean
  showNotificationPermissions?: boolean
  footnote?: string
}

export const EditMembershipView = ({ status, readonly, title, description, answers, fields, submitAction, handleCancelMembership, changeFormFieldValue, formData, hidePrivate, hideHeader, includeStatus, submitOnClick, showInfotext, showNotificationPermissions, footnote}: PropTypes) => {
  const user = useMyself()

  return (
    <form className="content space-y-8" onSubmit={e => {
      e.preventDefault();
      if (submitAction) {
        submitAction()
      }
    }}>
      <div className="space-y-8 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          {!hideHeader && (
            <div className={''}>
              <MainHeader
                title={title}
              />
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <div className={'prose max-w-full text-sm text-gray-700'}>
                    {parse(description)}
                  </div>
                  <div className={'flex items-center mt-4 text-gray-900'}>
                    <span className={'inline-block mr-2 font-medium'}>Mitgliedschaftsstatus:</span>

                    {status === 'requested' && (
                      <span className="inline-flex items-center rounded-md bg-yellow-50 mr-2 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                        Angefragt
                      </span>
                    )}
                    {status === 'active' && (
                      <span className="inline-flex items-center rounded-md bg-green-50 mr-2 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Aktiv
                      </span>
                    )}

                    {!readonly && (
                      <button
                        onClick={handleCancelMembership}
                        className="inline-flex items-center rounded-md bg-red-50 mr-2 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/40"
                      >
                        <ArrowRightOnRectangleIcon className={'w-4 h-4 mr-1'} />
                        Gruppe verlassen
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={classNames(
            "space-y-6 sm:space-y-5 sm:divide-y sm:divide-gray-200",
            !hideHeader ? 'sm:border-t sm:border-gray-200' : ''
          )}>
            {fields.map(property => property && (!property.isPrivate || !hidePrivate) && (
              <EditMembershipFieldView
                key={property.id}
                property={property}
                answer={formData[property.id]?.value}
                changeAnswer={value => changeFormFieldValue(property.id, value)}
                submitOnClick={submitOnClick}
                readonly={readonly || property.isReadonly}
                showInfotext={showInfotext}
              />
            ))}

            {showNotificationPermissions && user && (
              <div className={'pt-4 sm:grid sm:grid-cols-3 sm:gap-2'}>
                <div className={'sm:col-start-2 sm:col-span-2 max-w-lg'}>
                  <EditUserForm
                    user={user}
                    onChange={changeFormFieldValue}
                    hidePersonalDataSettings
                  />
                </div>
              </div>
            )}

            {footnote && (
              <div className={'pt-4 sm:grid sm:grid-cols-3 sm:gap-2'}>
                <div className={'sm:col-start-2 sm:col-span-2 max-w-lg'}>
                  {parse(footnote)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {submitAction && (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <div />
          <div className="py-2 mb-3 sm:col-span-2">
            <div className="flex justify-end max-w-lg">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                Speichern
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  )
}
