import { PencilIcon } from "@heroicons/react/24/outline";
import React from "react";
import { APP_STATUS_LABEL_ASSIGN } from "../../_constants/permissions";
import { Badge } from "../../_ui/Badge";
import { StatusLabel } from "../../types/graphql/StatusLabel";
import { useMyself } from "../UserContext";

type PropTypes = {
  statusLabels: StatusLabel[]
  openDialog: () => void,
  hasAvailableLabels: boolean
}

export const StatusLabelView = ({ statusLabels, openDialog, hasAvailableLabels }: PropTypes) => {
  const { canAccess } = useMyself() || {}

  return (
    <>
      <div className={'text-gray-700 self-start'}>Label:</div>
      <div className={'flex flex-wrap gap-2 col-span-2'}>
        {statusLabels.length > 0 ?
          statusLabels.map((label) => (
            <Badge key={label.id} color={label.color}>
              {label.title}
            </Badge>
          )) : (
            <span className={'text-xs text-gray-600 px-0 py-0.5'}>
              Noch kein Label hinterlegt
            </span>
          )
        }
      </div>
      {canAccess && canAccess(APP_STATUS_LABEL_ASSIGN) && (
        <button className={'flex justify-center disabled:text-gray-400'} onClick={openDialog} disabled={!hasAvailableLabels}>
          <PencilIcon className={'w-4 h-4'} />
        </button>
      )}
    </>
  )
}
