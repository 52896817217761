import { useQuery } from "urql";
import { readSeasonsQuery } from "../../_lib/graphql/queries";
import { Dialog } from "../../_ui/Dialog";
import { Season as SeasonTyp } from "../../types/graphql/Season";
import { useSchedule } from "../ScheduleContext";
import { Season } from "./components/Season";

type PropTypes = {
  open: boolean
  onClose: () => void
}

export const SwitchSeasonDialog = ({ open, onClose }: PropTypes) => {
  const { season: currentSeason } = useSchedule() || {}
  const [seasonsResult] = useQuery({
    query: readSeasonsQuery,
    pause: !open
  })

  return (
    <Dialog title={'Saison wechseln'} open={open} onClose={onClose}>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
        {seasonsResult.data?.readSeasons.map((season: SeasonTyp) => (
          <Season
            key={season.id}
            season={season}
            currentSeason={currentSeason}
            onClose={onClose}
          />
        ))}
      </div>
    </Dialog>
  )
}
