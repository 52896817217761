import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { classNames } from "../_helpers";

type PropTypes = {
  children: React.ReactNode
  title: string | React.ReactNode
  open: boolean
  hideCloseButton?: boolean
  onClose: () => void
  separator?: boolean
  className?: string
}

export const Dialog = ({title, open, onClose, separator, hideCloseButton, children, className}: PropTypes) => (
  <Transition.Root show={open} as={Fragment}>
    <HeadlessDialog open={open} as="div" className="relative z-10" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <HeadlessDialog.Panel className={classNames(
              'relative transform rounded-lg bg-white w-full px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6',
              className ? className : 'sm:max-w-xl'
            )}>
              <div>
                <div className={'flex justify-between'}>
                  <HeadlessDialog.Title as="h3" className="mb-3 text-lg text-center font-medium leading-6 text-gray-900">
                    {title}
                  </HeadlessDialog.Title>
                  {!hideCloseButton && (
                    <button onClick={onClose} className={'mb-3 focus:outline-none focus-visible:outline-none'}>
                      <XMarkIcon className={'w-5 h-5'} />
                    </button>
                  )}
                </div>

                {separator && (
                  <div className={'h-4 mb-4 -mx-4 sm:-mx-6 bg-primary-300'} />
                )}

                {children}
              </div>
            </HeadlessDialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </HeadlessDialog>
  </Transition.Root>
)
