import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { APP_CHECKIN_PASSES, APP_EDIT_PASSES } from "../../_constants/permissions";
import {
  addPassMutation,
  checkinPassMutation,
  deletePassMutation,
  updatePassMutation
} from "../../_lib/graphql/mutations";
import { readAllUserNamesQuery, readOnePassQuery } from "../../_lib/graphql/queries";
import { Button } from "../../_ui/Button";
import { Dialog } from "../../_ui/Dialog";
import { Fieldgroup, Selectfield, Textfield } from "../../_ui/forms";
import { User } from "../../types/graphql/User";
import { AddUserInput } from "../AddUserInput";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";
import { useMyself } from "../UserContext";

type PropTypes = {
  newPass?: boolean
  open: boolean
  onClose: () => void
}

export const PassEditDialog = ({ newPass, open, onClose }: PropTypes) => {
  const { canAccess } = useMyself() || {}
  const [searchParams] = useSearchParams();
  const { pushError, pushSuccess } = useSnackbarPush()
  const [passType, setPassType] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [userType, setUserType] = useState('')
  const [firstName, setFirstName] = useState('')
  const [surname, setSurname] = useState('')
  const [portalUserID, setPortalUserID] = useState<User | null>(null)
  const [passID, setPassID] = useState('')
  const [confirmDelete, setConfirmDelete] = useState(false)

  const [passResult] = useQuery({
    query: readOnePassQuery,
    variables: {
      passID: searchParams.get('pass')
    },
    pause: !searchParams.get('pass') || !open
  })

  const [checkinState, checkinPass] = useMutation(checkinPassMutation)

  const handleCheckin = useCallback(() => {
    checkinPass({
      input: {
        id: searchParams.get('pass')
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Ausweis ausgegeben')
        setPassID(res.data?.checkinPass?.passID)
      }
    })
  }, [checkinPass, pushError, pushSuccess, searchParams])

  useEffect(() => {
    const pass = passResult.data?.readOnePass
    if (pass) {
      setPassType(pass.type)
      setOrganisation(pass.organisation)
      setUserType(pass.userType)
      setFirstName(pass.firstName)
      setSurname(pass.surname)
    }
  }, [passResult.data?.readOnePass])

  const [usersResult] = useQuery({
    query: readAllUserNamesQuery,
    pause: !open
  })

  useEffect(() => {
    const pass = passResult.data?.readOnePass
    if (pass?.user?.id) {
      const user = (usersResult.data?.readAllUsers || []).find((item: User) => item.id === pass.user.id)
      setPortalUserID(user || null)
    }
  }, [passResult.data?.readOnePass, passResult.data?.readOnePass.user.id, usersResult.data?.readAllUsers])

  const [addState, addPass] = useMutation(addPassMutation)
  const [updateState, updatePass] = useMutation(updatePassMutation)
  const [deleteState, deletePass] = useMutation(deletePassMutation)

  const handleAdd = useCallback(() => {
    addPass({
      input: {
        passType,
        organisation,
        userType,
        firstName,
        surname,
        portalUserID: portalUserID?.id
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Ausweis angelegt')
        setFirstName('')
        setSurname('')
        setPortalUserID(null)
        onClose()
      }
    })
  }, [addPass, firstName, onClose, organisation, passType, portalUserID?.id, pushError, pushSuccess, surname, userType])

  const handleUpdate = useCallback(() => {
    updatePass({
      input: {
        id: searchParams.get('pass'),
        passType,
        organisation,
        userType,
        firstName,
        surname,
        portalUserID: portalUserID?.id
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Ausweis gespeichert')
        onClose()
      }
    })
  }, [firstName, onClose, organisation, passType, portalUserID?.id, pushError, pushSuccess, searchParams, surname, updatePass, userType])

  const handleDelete = useCallback(() => {
    deletePass({
      input: {
        id: searchParams.get('pass'),
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Ausweis gelöscht')
        onClose()
      }
    })
  }, [deletePass, onClose, pushError, pushSuccess, searchParams])

  const buttonDisabled = useMemo(() => {
    return !organisation || !passType || !userType || (userType === 'internal' && !portalUserID)
  }, [organisation, passType, portalUserID, userType])

  if (!canAccess || !canAccess([APP_EDIT_PASSES, APP_CHECKIN_PASSES])) {
    return null
  }

  return (
    <Dialog separator title={newPass ? 'Neuen Ausweis anlegen' : 'Ausweis bearbeiten'} open={open} onClose={onClose}>
      <Selectfield
        label={'Ausweis-Typ'}
        value={passType}
        setValue={setPassType}
        disabled={!newPass && passType !== ''}
      >
        <option value={'AAA'}>AAA</option>
        <option value={'Artist'}>Artist</option>
        <option value={'Press'}>Presse</option>
      </Selectfield>

      <Textfield label={'Organisation'} value={organisation} setValue={setOrganisation} />

      <Selectfield label={'Benutzertyp'} value={userType} setValue={setUserType}>
        <option value={'internal'}>Intern</option>
        <option value={'external'}>Extern</option>
      </Selectfield>

      {userType === 'external' && (
        <>
          <Textfield label={'Vorname'} value={firstName} setValue={setFirstName} />
          <Textfield label={'Nachname'} value={surname} setValue={setSurname} />
        </>
      )}

      {userType === 'internal' && (
        <Fieldgroup label={'Helfer:in'}>
          <AddUserInput
            user={usersResult.data?.readAllUsers || []}
            selectedPerson={portalUserID}
            setSelectedPerson={setPortalUserID}
          />
        </Fieldgroup>
      )}

      <div className={'flex justify-end mt-4 gap-2'}>
        {!newPass && (
          <Button color={"error"} onClick={() => setConfirmDelete(true)} disabled={passResult.data?.readOnePass?.passID || checkinState.fetching || addState.fetching || updateState.fetching || deleteState.fetching || buttonDisabled}>
            Löschen
          </Button>
        )}

        <Button color={"default"} onClick={handleCheckin} disabled={passResult.data?.readOnePass?.passID || checkinState.fetching || addState.fetching || updateState.fetching || deleteState.fetching || buttonDisabled}>
          Ausgeben
        </Button>

        <Button type={"submit"} color={"primary"} onClick={newPass ? handleAdd : handleUpdate} disabled={addState.fetching || updateState.fetching || deleteState.fetching || buttonDisabled}>
          Speichern
        </Button>
      </div>

      {!newPass && (
        <Dialog title={'Soll der Ausweis wirklich gelöscht werden?'} open={confirmDelete} onClose={() => setConfirmDelete(false)}>
          {passID && (
            <div className={'my-5 text-center text-4xl'}>{passID.toString().padStart(3, '0')}</div>
          )}

          <div className={'grid grid-cols-2 gap-2'}>
            <Button fullWidth onClick={() => setConfirmDelete(false)}>
              Zurück
            </Button>
            <Button color={"error"} fullWidth onClick={() => {
              setConfirmDelete(false)
              handleDelete()
            }}>
              Ja, Ausweis löschen
            </Button>
          </div>
        </Dialog>
      )}

      <Dialog title={'Pass ID eintragen'} open={!!passID} onClose={() => setPassID('')}>
        <div className={'my-5 text-center text-4xl'}>{passID.toString().padStart(3, '0')}</div>

        <Button fullWidth onClick={() => {
          setPassID('')
          onClose()
        }}>
          Fertig, Ausweis ausgegeben
        </Button>
      </Dialog>
    </Dialog>
  )
}
