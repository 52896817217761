import React from "react";
import { User } from "../../types/graphql/User";
import { UserList as UserListView } from "./UserList";

type PropTypes = {
  user: User[]
  fetching: boolean
  skeletonAmount?: number
}

export const UserList = ({ user, fetching, skeletonAmount = 3 }: PropTypes) => {
  return (
    <div className={'grid gap-4'}>
      <UserListView
        skeletonAmount={skeletonAmount}
        fetching={fetching}
        users={user}
      />
    </div>
  )
}
