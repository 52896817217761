import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "urql";
import { DATEOPTIONS } from "../../_constants/datetime";
import { updateUserMutation } from "../../_lib/graphql/mutations";
import { Checkbox, Textfield } from "../../_ui/forms";
import { User } from "../../types/graphql/User";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";

type PropTypes = {
  user: User
  hideNotificationSettings?: boolean
  hidePersonalDataSettings?: boolean
  onChange?: (propertyID: string, value: boolean | string | string[]) => void
  submitAction?: (user: User) => void
}

const propertyLabels = {
  firstName: 'Vorname',
  surname: 'Nachname',
  nickname: 'Rufname, wenn abweichend',
  birthday: 'Geburtsdatum',
  email: 'Email',
  phone: 'Telefonnummer',
  street: 'Straße, Nr.',
  zip: 'PLZ',
  city: 'Ort',
  notificationGeneral: 'Allgemeine Infos',
  notificationMarketing: 'Marketingdinge',
  notificationBirthday: 'An meinem Geburtstag',
  notificationInvitations: 'Einladungen',
  notificationSupport: 'Support von Freunden'
}

const propertyDescriptions = {
  notificationGeneral: 'Alles, was im Verein und in der Planung gerade so abgeht',
  notificationMarketing: 'Bspw. Band oder Merchveröffentlichungen',
  notificationBirthday: 'Der wichtigste Tag des Jahres!',
  notificationInvitations: 'Zu Konzerten, Vereinsveranstaltungen, Planungscafe, Events u. a.',
  notificationSupport: 'Wie bspw. Helferaufrufe für das Summertime Festival'
}

export const EditUserForm = ({user, onChange, submitAction, hideNotificationSettings, hidePersonalDataSettings}: PropTypes) => {
  const { pushSuccess, pushError } = useSnackbarPush()
  const [formData, setFormData] = useState<any>({})
  const timeoutRef = useRef(2000)
  const formDataRef = useRef<any>({})

  const [, updateProperty] = useMutation(updateUserMutation)

  const initializeForm = useCallback(() => {
    setFormData(() => ({
      firstName: user?.firstName || '',
      surname: user?.surname || '',
      nickname: user?.nickname || '',
      birthday: user?.birthday ? new Date(user.birthday as string).toLocaleDateString('de-DE', DATEOPTIONS as any) : '',
      email: user?.email || '',
      phone: user?.phone || '',
      street: user?.street || '',
      zip: user?.zip || '',
      city: user?.city || '',
      notificationGeneral: user?.notificationGeneral || '',
      notificationMarketing: user?.notificationMarketing || '',
      notificationBirthday: user?.notificationBirthday || '',
      notificationInvitations: user?.notificationInvitations || '',
      // notificationSupport: user?.notificationSupport || '',
    }))
  }, [user, setFormData])

  useEffect(() => {
    initializeForm()
  }, [user, initializeForm])

  const changeFormFieldValue = useCallback((property: string, value: string | boolean) => {
    const counter = formDataRef.current[property]?.counter + 1 || 1
    formDataRef.current = {
      ...formDataRef.current,
      [property]: {
        value: typeof value === 'boolean' ? value : value.toString(),
        counter
      }
    }

    setFormData((prev: User) => ({
      ...prev,
      [property]: value
    }))

    if (onChange) {
      onChange(property, value)
    }

    if (!submitAction && !onChange) {
      setTimeout(() => {
        if (formDataRef.current[property].value === value && formDataRef.current[property].counter === counter) {
          updateProperty({
            input: {
              userID: user.id,
              property,
              value: value.toString()
            }
          }).then(res => {
            if (res.error) {
              pushError(res.error.message)
              initializeForm()
            } else {
              pushSuccess('Speichern erfolgreich')
            }
          })
          timeoutRef.current = 2000
        }
      }, timeoutRef.current)
    }
  }, [submitAction, updateProperty, user, pushError, initializeForm, pushSuccess, onChange])


  const handleFormSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault()

    if (submitAction) {
      submitAction({
        id: user.id,
        ...formData
      })
    }
  }, [submitAction, user, formData])

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={'flex flex-col gap-2 sm:gap-0'}>
        {!hidePersonalDataSettings && Object.keys(formData).filter(property => !property.startsWith('notification')).map(property => (
          <Fragment key={property}>
            {property === 'notificationGeneral' && (
              <>
                <hr className={'my-4'} />
                <h4 className={'text-lg font-bold mb-1'}>Benachrichtigungen</h4>
                <p className={'mb-3 text-sm'}>Gib an, zu welchen Ereignissen wir dich abseits der Planung und Durchführung des Rock am Beckenrand benachrichtigen dürfen.</p>
              </>
            )}
            {property.startsWith('notification') ? (
              <div className={'py-1'}>
                <Checkbox
                  label={`${propertyLabels[property as keyof {}]}${(user?.requiredNotificationPermissions || []).map(i => i.toLowerCase()).includes(property.toLowerCase()) ? ' *' : ''}` || ''}
                  description={propertyDescriptions[property as keyof {}] || ''}
                  checked={formData[property as keyof {}] || false}
                  isRequired={(user?.requiredNotificationPermissions || []).map(i => i.toLowerCase()).includes(property.toLowerCase())}
                  setValue={(value) => changeFormFieldValue(property, value)}
                  disabled={(user?.requiredNotificationPermissions || []).map(i => i.toLowerCase()).includes(property.toLowerCase()) && user[property as keyof {}]}
                />
              </div>
            ) : (
              <Textfield
                key={property}
                label={`${propertyLabels[property as keyof {}]} ${!['nickname'].includes(property) ? '*' : ''}` || ''}
                value={formData[property as keyof {}] || ''}
                setValue={(value) => changeFormFieldValue(property, value)}
                mask={property === 'birthday' ? '99.99.9999' : ''}
                isRequired={!['nickname'].includes(property)}
              />
            )}
          </Fragment>
        ))}

        {!hideNotificationSettings && Object.keys(formData).filter(property => property.startsWith('notification')).map(property => (
          <Fragment key={property}>
            {property === 'notificationGeneral' && (
              <>
                {!hidePersonalDataSettings && (
                  <hr className={'my-4'} />
                )}
                <h4 className={'text-lg font-bold mb-1'}>Benachrichtigungen</h4>
                <p className={'mb-3 text-sm'}>Gib an, zu welchen Ereignissen wir dich abseits der Planung und Durchführung des Rock am Beckenrand benachrichtigen dürfen.</p>
              </>
            )}
            {property.startsWith('notification') ? (
              <div className={'py-1'}>
                <Checkbox
                  label={`${propertyLabels[property as keyof {}]}${(user?.requiredNotificationPermissions || []).map(i => i.toLowerCase()).includes(property.toLowerCase()) ? ' *' : ''}` || ''}
                  description={propertyDescriptions[property as keyof {}] || ''}
                  checked={formData[property as keyof {}] || false}
                  isRequired={(user?.requiredNotificationPermissions || []).map(i => i.toLowerCase()).includes(property.toLowerCase())}
                  setValue={(value) => changeFormFieldValue(property, value)}
                  disabled={(user?.requiredNotificationPermissions || []).map(i => i.toLowerCase()).includes(property.toLowerCase()) && user[property as keyof {}]}
                />
              </div>
            ) : (
              <Textfield
                key={property}
                label={`${propertyLabels[property as keyof {}]} *` || ''}
                value={formData[property as keyof {}] || ''}
                setValue={(value) => changeFormFieldValue(property, value)}
                mask={property === 'birthday' ? '99.99.9999' : ''}
                isRequired
              />
            )}
          </Fragment>
        ))}
      </div>

      {submitAction && (
        <div className="mt-4 sm:mt-6 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-2">
          <div />
          <div className="py-2 mb-3 sm:col-span-2">
            <div className="flex justify-end max-w-lg">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                Speichern
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  )
}
