import { useCallback, useState } from "react";
import { useMutation } from "urql";
import { updateCheckinTimeForUserMutatioon } from "../../_lib/graphql/mutations";
import { Button } from "../../_ui/Button";
import { Selectfield, Textfield } from "../../_ui/forms";
import { User } from "../../types/graphql/User";
import { useSchedule } from "../ScheduleContext";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";

type PropTypes = {
  handleBack?: () => void
  user: User
}

export const EditCheckinDates = ({handleBack, user}: PropTypes) => {
  const { dates } = useSchedule() || {}
  const { pushSuccess, pushError } = useSnackbarPush()

  const [arrivalDate, setArrivalDate] = useState(() => {
    return user.currentSeasonCheckin?.arrivalDate || ''
  })
  const [departureDate, setDepartureDate] = useState(() => {
    return user.currentSeasonCheckin?.departureDate || ''
  })
  const [arrivalTime, setArrivalTime] = useState(() => {
    return user.currentSeasonCheckin?.arrivalTime ? user.currentSeasonCheckin?.arrivalTime.slice(0, 5) : ''
  })
  const [departureTime, setDepartureTime] = useState(() => {
    return user.currentSeasonCheckin?.departureTime ? user.currentSeasonCheckin?.departureTime.slice(0, 5) : ''
  })

  const [state, updateCheckinTimeForUser] = useMutation(updateCheckinTimeForUserMutatioon)

  const handleUpdateCheckinForUser = useCallback(() => {
    updateCheckinTimeForUser({
      input: {
        userID: user.id,
        arrivalDate,
        departureDate,
        arrivalTime,
        departureTime
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Checkin aktualisiert')
        if (handleBack) {
          handleBack()
        }
      }
    })
  }, [arrivalDate, arrivalTime, departureDate, departureTime, handleBack, pushError, pushSuccess, updateCheckinTimeForUser, user.id])

  return (
      <form onSubmit={e => {
        e.preventDefault()
        handleUpdateCheckinForUser()
      }}>
        <div className={'grid sm:grid-cols-2 gap-y-2 sm:gap-y-0 gap-x-2 items-center'}>
          <div className={'sm:col-span-2'}>
            Anreise
          </div>
          <div>
            <Selectfield
              small
              vertical
              label={'Datum'}
              value={arrivalDate}
              setValue={value => setArrivalDate(value)}
              disabled={(dates && dates[dates.length - 1] < new Date()) as boolean}
            >
              {dates && dates?.map(date => (
                <option key={date.toISOString().slice(0, 10)} value={date.toISOString().slice(0, 10)}>
                  {date.toLocaleDateString('de-DE', {
                    weekday: 'short',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </option>
              ))}
            </Selectfield>
          </div>
          <div>
            <Textfield
              hasHourMask
              small
              vertical
              label={'Uhrzeit (optional)'}
              value={arrivalTime}
              setValue={value => setArrivalTime(value)}
              disabled={(dates && dates[dates.length - 1] < new Date()) as boolean}
            />
          </div>

          <div className={'sm:col-span-2 mt-4'}>
            Abreise
          </div>
          <div>
            <Selectfield
              small
              vertical
              label={'Datum'}
              value={departureDate}
              setValue={value => setDepartureDate(value)}
              disabled={(dates && dates[dates.length - 1] < new Date()) as boolean}
            >
              {dates && dates?.map(date => (
                <option key={date.toISOString().slice(0, 10)} value={date.toISOString().slice(0, 10)}>
                  {date.toLocaleDateString('de-DE', {
                    weekday: 'short',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </option>
              ))}
            </Selectfield>
          </div>
          <div>
            <Textfield
              hasHourMask
              small
              vertical
              label={'Uhrzeit (optional)'}
              value={departureTime}
              setValue={value => setDepartureTime(value)}
              disabled={(dates && dates[dates.length - 1] < new Date()) as boolean}
            />
          </div>
        </div>

        {dates && dates[dates.length - 1] >= new Date() && (
          <div className={'flex justify-end mt-4'}>
            <Button type={"submit"} color={"primary"} disabled={state.fetching}>
              Speichern
            </Button>
          </div>
        )}
      </form>
  )
}
