import { LockClosedIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useMemo } from "react";
import { useMutation } from "urql";
import { addSectionToCurrentSeasonMutation } from "../../../_lib/graphql/mutations";
import { Checkbox } from "../../../_ui/forms";
import { Section as SectionType } from "../../../types/graphql/Section";
import { User } from "../../../types/graphql/User";
import { useSnackbarPush } from "../../SnackbarContext/SnackbarContext";
import { SectionRole } from "./SectionRole";

type PropTypes = {
  section: SectionType,
  profile: User
}

export const Section = ({section, profile}: PropTypes) => {
  const { pushSuccess, pushError } = useSnackbarPush()
  const [, addSectionToCurrentSeason] = useMutation(addSectionToCurrentSeasonMutation)

  const handleChange = useCallback((checked: boolean) => {
    addSectionToCurrentSeason({
      input: {
        userID: profile.id,
        sectionID: section.id,
        remove: !checked
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Speichern erfolgreich')
      }
    })
  }, [profile, section, addSectionToCurrentSeason, pushSuccess, pushError])

  const checked = useMemo(() => {
    return (profile.sections || []).findIndex(s => s.id === section.id) >= 0
  }, [profile, section])

  const disabled = useMemo(() => {
    return typeof profile.sectionLeads?.find(item => item.id === section.id) !== 'undefined'
  }, [profile, section])

  return (
    <div className={'mb-2 col-span-2'}>
      <h3 className={'pb-2'}>{section.title}</h3>

      {disabled && (
        <p className={'flex text-xs pb-4 text-gray-600'}>
          <LockClosedIcon className={'w-4 h-4 mr-2'} />
          Dieser Bereich kann aufgrund der Leitungsposition nicht ausgwählt werden.
        </p>
      )}

      <div className={'mb-2'}>
        <Checkbox
          bold
          label={'Ganzer Bereich'}
          checked={checked}
          disabled={disabled}
          setValue={handleChange}
        />
      </div>

      {!checked && section.sectionRoles.map(sectionRole => (
        <SectionRole
          key={sectionRole.id}
          sectionRole={sectionRole}
          profile={profile}
          disabled={disabled}
        />
      ))}
    </div>
  )
}
