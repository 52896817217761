import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

type PropTypes = {
  title: string
  onClose: () => void
}

export const SlideOverDialogHeader = ({title, onClose}: PropTypes) => (
  <div className="bg-gray-50 px-4 py-6 sm:px-6">
    <div className="flex items-start justify-between space-x-3">
      <div className="space-y-1">
        <Dialog.Title className="text-lg font-medium text-gray-900">
          {title}
        </Dialog.Title>
        {/*<p className="text-sm text-gray-500">*/}
        {/*  Get started by filling in the information below to create your new project.*/}
        {/*</p>*/}
      </div>
      <div className="flex h-7 items-center">
        <button
          type="button"
          className="text-gray-400 hover:text-gray-500 focus:outline-0"
          onClick={onClose}
        >
          <span className="sr-only">Close panel</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
)
