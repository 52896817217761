import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "urql";
import { APP_CHECKIN } from "../../_constants/permissions";
import { classNames } from "../../_helpers";
import { checkinUserMutation } from "../../_lib/graphql/mutations";
import { Spinner } from "../../_ui";
import { Button } from "../../_ui/Button";
import { Dialog } from "../../_ui/Dialog";
import { SeasonCheckin } from "../../types/graphql/SeasonCheckin";
import { SectionSlot, TypedSectionSlot } from "../../types/graphql/SectionSlot";
import { SectionSlotLead, TypedSectionSlotLead } from "../../types/graphql/SectionSlotLead";
import { EditProfileImage } from "../EditUser/components/EditProfileImage";
import { NotAllowed } from "../NotAllowed";
import { PassEditDialog } from "../PassEditDialog";
import { useSchedule } from "../ScheduleContext";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";
import { useMyself } from "../UserContext";
import { CheckinItem } from "./components/CheckinItem";
import { PropertyAnswerView } from "./components/PropertyAnswer";

export type HistoryItem = {
  id: string
  userId: string
  firstName: string
  surname: string
  time: Date
}

type PropTypes = {
  seasonCheckin?: SeasonCheckin
  open: boolean
  loading: boolean
  onClose: () => void
}

export const CheckinDialog = ({ seasonCheckin, loading, open, onClose }: PropTypes) => {
  const  { season } = useSchedule() || {}
  const { pushSuccess, pushError } = useSnackbarPush()
  const { canAccess } = useMyself() || {}
  const [searchParams, setSearchParams] = useSearchParams()

  const [, checkinUser] = useMutation(checkinUserMutation)

  const handleCheckin = useCallback(() => {
    if (!seasonCheckin || !seasonCheckin.user) {
      return
    }

    checkinUser({
      input: {
        userID: seasonCheckin.user.id,
        seasonCheckinID: seasonCheckin.id
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Check-In erfolgreich')
      }
    })
  }, [checkinUser, seasonCheckin, pushError, pushSuccess])

  const handlePassOpen = useCallback((passID: string) => {
    setSearchParams(prev => {
      prev.set('pass', passID)
      return prev
    })
  }, [setSearchParams])

  // const passTypeString = useMemo<string>((pass: Pass) => {
  //   switch (pass.type) {
  //     case 'AAA':
  //       return 'AAA'
  //     case 'Press':
  //       return 'Presse'
  //     default:
  //       return 'n.a.'
  //   }
  // }, [])

  const age = useMemo(() => {
    var today = new Date();
    var birthDate = new Date((seasonCheckin?.user?.birthday || '').replace(/-/g, '/'));
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }, [seasonCheckin?.user?.birthday])

  const groupedAcceptedSlots = useMemo(() => {
    const source = [
      ...(seasonCheckin?.user?.acceptedSlots || []).filter(item => !item.sectionRole?.shiftExemption).map(item => ({...item, typename: 'SectionSlot'})),
      ...(seasonCheckin?.user?.acceptedSlotLeads || []).map(item => ({...item, typename: 'SectionSlotLead'})),
    ]

    return source.reduce((acc: {[date: string]: TypedSectionSlot[] | TypedSectionSlotLead[]}, item: TypedSectionSlot | TypedSectionSlotLead) => {
      return {
        ...acc,
        [item.begin.slice(0, 10)]: [
          ...(acc[item.begin.slice(0, 10)] || []),
          item
        ]
      }
    }, {})
  }, [seasonCheckin?.user?.acceptedSlotLeads, seasonCheckin?.user?.acceptedSlots])

  const collapsedAcceptedSlots = useMemo(() => {
    return Object.keys(groupedAcceptedSlots).reduce((acc, date) => {
      return {
        ...acc,
        // @ts-ignore
        [date]: (groupedAcceptedSlots[date] || []).reduce((acc2: any, slot: TypedSectionSlot | TypedSectionSlotLead) => {
          if (slot.begin === acc2[0]?.end && slot.sectionRole?.id === acc2[0]?.sectionRole?.id) {
            return [
              {
                ...acc2[0],
                id: acc2[0].id + slot.id,
                end: slot.end
              },
              ...(acc2.slice(1) || [])
            ]
          } else {
            return [
              slot,
              ...acc2
            ]
          }
        }, []).reverse()
      }
    }, {})
  }, [groupedAcceptedSlots])

  const totalHelpTime = useMemo(() => {
    return (seasonCheckin?.user?.acceptedSlots || []).reduce((acc: number, slot: SectionSlot) => {
      // @ts-ignore
      const time = new Date(slot.end?.replace(/-/g, '/')) - new Date(slot.begin?.replace(/-/g, '/'))
      return acc + (time / (1000 * 60 * 60));
    }, 0)
  }, [seasonCheckin?.user?.acceptedSlots])

  if (!canAccess || !canAccess(APP_CHECKIN)) {
    return <NotAllowed />
  }

  // @ts-ignore
  return (
    <Dialog
      title={seasonCheckin && !loading ? `${seasonCheckin?.user?.firstName} ${seasonCheckin?.user?.surname}` : <Skeleton width={100} />}
      open={open}
      onClose={onClose}
    >
      {seasonCheckin && !loading ? (
        <>
          <EditProfileImage user={seasonCheckin.user} />

          <div className={'mb-5'}>
            <h3 className={'font-medium text-lg'}>Informationen</h3>
            <div className={'text-sm divide-y border-b'}>

              <div className={'grid grid-cols-2 py-1'}>
                <div>
                  Anmeldedatum
                </div>
                <div className={classNames(
                  'flex items-center gap-2',
                  (seasonCheckin.user?.targetGroupRegistrationDate || '') > '2024-08-19 00:00:00' ? 'text-red-600 font-bold' : ''
                )}>
                  {(seasonCheckin.user?.targetGroupRegistrationDate || '') > '2024-08-19 00:00:00' && (
                    <ExclamationTriangleIcon className={'w-4 h-4'} />
                  )}
                  {seasonCheckin.user?.targetGroupRegistrationDate
                    ? new Date(seasonCheckin?.user?.targetGroupRegistrationDate || '').toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})
                    : 'Nicht in Festivalhelfer Gruppe'
                  }
                </div>
              </div>

              <div className={'grid grid-cols-2 py-1'}>
                <div>
                  Geburtsdatum
                </div>

                <div className={classNames(
                  'flex items-center gap-2',
                  age < 16 ? 'text-green-500' : '',
                  age < 18 && age >= 16 ? 'text-red-500' : '',
                )}>
                  {age < 18 && <ExclamationTriangleIcon className={'text-red-500 w-5 h-5'} /> }
                  {new Date((seasonCheckin?.user?.birthday || '').replace(/-/g, '/')).toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit', year: 'numeric'})} ({age})
                </div>
              </div>

              <div className={'grid grid-cols-2 py-1'}>
                <div>
                  Helfer:innen Stunden
                </div>
                <div>
                  {totalHelpTime} Stunden
                  {seasonCheckin.user?.hasShiftExemption && (
                    <>
                      <br />
                      schichtbefreit
                    </>
                  )}
                </div>
              </div>

              {(seasonCheckin.checkinProperties || []).map(property => (
                <PropertyAnswerView checkinProperty={property} checkinPropertyAnswers={seasonCheckin.checkinPropertyAnswers} />
              ))}
            </div>
          </div>

          {(seasonCheckin.user?.passes?.length || 0) > 0 && (
            <div className={'mb-5'}>
              <h3 className={'block mb-2 font-medium text-lg'}>Ausweise</h3>

              <div className={'grid gap-2 divide-y'}>
                {seasonCheckin.user?.passes?.map(pass => (
                  <div className={'pt-2 flex cursor-pointer'} onClick={() => handlePassOpen(pass.id)}>
                    {pass.type}
                    <div className={'flex items-center gap-2 ml-auto'}>
                      <span className={'text-xs'}>({pass.passID.toString().padStart(3, '0')})</span>
                      {pass.passID ? (
                        <CheckCircleIcon className={'w-6 h-6 text-lime-600'} />
                      ) : (
                        <XCircleIcon className={'w-6 h-6 text-red-600'} />
                      )}
                    </div>

                    <PassEditDialog open={searchParams.get('pass') === pass.id} onClose={() => setSearchParams({})} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className={'mb-5'}>
            <h3 className={'block mb-2 font-medium text-lg'}>Festival Check-In</h3>
            <Button onClick={handleCheckin} fullWidth size={'large'} color={seasonCheckin.checkinTime === null ? 'error' : 'success'}>
              {seasonCheckin.checkinTime
                ? (
                  <>
                    <CheckIcon className={'w-5 h-5 mr-2'} />
                    {new Date(seasonCheckin.checkinTime).toLocaleString('de', {
                      weekday: 'long',
                      year: "numeric",
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}&nbsp;Uhr
                  </>
                ) : 'Check-In ausführen'
              }
            </Button>
          </div>

          {(season?.seasonCheckinItems || []).length > 0 && (
            <div className={'mb-5'}>
              <h3 className={'font-medium text-lg'}>Goodies</h3>
              <ul className={'mt-2 divide divide-y'}>
                {(season?.seasonCheckinItems || []).map(item => seasonCheckin && (
                  <CheckinItem
                    key={item.id}
                    item={item}
                    seasonCheckin={seasonCheckin}
                    seasonCheckinItems={seasonCheckin?.seasonCheckinItems || []}
                  />
                ))}
              </ul>
            </div>
          )}

          <div className={'mb-5'}>
            <h3 className={'font-medium text-lg mb-2'}>Schichten</h3>

            <div className={'text-sm text-gray-700 mb-2'}>
              Gesamt: {totalHelpTime} Stunden
            </div>

            {Object.keys(collapsedAcceptedSlots).map((date: string) => (
              <React.Fragment key={date}>
                <h2 className={'font-medium'}>{new Date(date?.replace(/-/g, '/')).toLocaleDateString('de-DE', {
                  weekday: 'long',
                  day: '2-digit',
                  month: '2-digit'
                })}</h2>

                <ul className={'mt-2 divide divide-y text-gray-700 text-sm mb-2'}>
                  {//@ts-ignore
                    (collapsedAcceptedSlots[date] || []).map((item: (SectionSlot & {typename: string}) | (SectionSlotLead & {typename: string})) => (
                    <li className={'grid grid-cols-2'}>
                      <div>
                        {new Date(item.begin?.replace(/-/g, '/')).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'})}
                        &nbsp;&ndash;&nbsp;
                        {new Date(item.end?.replace(/-/g, '/')).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'})}
                      </div>
                      <div>
                        {item.sectionRole?.title }
                      </div>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </div>
        </>
      ) : (
        <Spinner size={'w-8 h-8'} color={'text-gray-400'} />
      )}
    </Dialog>
  )
}
