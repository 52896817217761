import { Button } from "../../_ui/Button";
import { Dialog } from "../../_ui/Dialog";

type PropType = {
  open: boolean
  onClose: () => void
  cancelMembership: () => void
}

export const CancelDialog = ({ open, onClose, cancelMembership }: PropType) => {
  return (
    <Dialog title={'Mitgliedschaft beenden'} open={open} onClose={onClose}>
      <div>
        Soll die Mitgliedschaft wirklich beendet werden?
      </div>
      <div className={'flex justify-end gap-4 mt-4'}>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={cancelMembership} color={'primary'}>Mitgliedschaft beenden</Button>
      </div>
    </Dialog>
  )
}
