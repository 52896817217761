import { useCallback, useMemo } from 'react'
import { useSearchParams } from "react-router-dom";
import ProfileView from "../_features/ProfileView";
import { SlideOverDialog } from "../_ui/SlideOverDialog";
import { SlideOverDialogContent } from "../_ui/SlideOverDialogContent";

export const SlideOver = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const open = useMemo(() => searchParams.get('modal')?.startsWith('user') || false, [searchParams])

  const onClose = useCallback(() => setSearchParams(prev => {
    return new URLSearchParams()
  }), [setSearchParams])

  const userID = useMemo(() => {
    if (open) {
      return searchParams.get('modal')?.replace('user-', '')
    }

    return null
  }, [open, searchParams])

  return (
    <SlideOverDialog open={open} onClose={onClose} title={'Mitglied bearbeiten'}>
      <SlideOverDialogContent>
        <ProfileView userID={userID} />
      </SlideOverDialogContent>
    </SlideOverDialog>
  )
}
