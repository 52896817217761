import { useCallback, useState } from "react";
import { useMutation, useQuery } from "urql";
import { resendRegistrationEmailMutation, updateMembershipStatusMutation } from "../../_lib/graphql/mutations";
import { readOneUserQuery, readSeasonsQuery } from "../../_lib/graphql/queries";
import { EditUser } from "../EditUser";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";
import { UserLogs } from "../UserLogs";
import { ProfileView } from "./ProfileView";

type PropTypes = {
  userID?: string | null
}

const ProfileViewProvider = ({ userID }: PropTypes) => {
  const { pushSuccess, pushError } = useSnackbarPush()
  const [editUserOpen, setEditUserOpen] = useState(false)
  const [logsOpen, setLogsOpen] = useState(false)
  const [userResult] = useQuery({
    query: readOneUserQuery,
    variables: {
      input: {
        userID: userID
      },
    },
    pause: !userID
  })

  const [seasonsResult] = useQuery({
    query: readSeasonsQuery
  })

  const [, executeUpdateMembershipStatus] = useMutation(updateMembershipStatusMutation)

  const updateMembershipStatus = useCallback((membershipID: string, status: string) => {
    executeUpdateMembershipStatus({
      input: {
        membershipID,
        status
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        switch (status) {
          case 'confirm':
            pushSuccess('Mitgliedschaft bestätigt')
            break;
          case 'reject':
            pushSuccess('Mitgliedschaft abgelehnt')
            break;
          case 'leave':
            pushSuccess('Mitgliedschaft beendet')
            break;
          case 'requested':
            pushSuccess('Mitgliedschaft zurückgesetzt auf "angefragt"')
          break;
        }
      }
    })
  }, [executeUpdateMembershipStatus, pushError, pushSuccess])

  const [, resendRegistrationEmail] = useMutation(resendRegistrationEmailMutation)

  const handleResendRegistrationEmail = useCallback(() => {
    resendRegistrationEmail({
      input: {
        userID
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Registrierungs E-Mail versendet')
      }
    })
  }, [resendRegistrationEmail, userID, pushError, pushSuccess])

  return (
    <>
      <EditUser
        hideNotificationSettings
        userID={userID || ''}
        open={editUserOpen}
        onClose={() => setEditUserOpen(false)}
      />

      <UserLogs
        userID={userID || ''}
        open={logsOpen}
        onClose={() => setLogsOpen(false)}
      />

      <ProfileView
        editProfile={() => setEditUserOpen(true)}
        openHistory={() => setLogsOpen(true)}
        profile={userResult.data?.readOneUser}
        seasons={seasonsResult.data?.readSeasons || []}
        fetching={userResult.fetching }
        stale={userResult.stale}
        updateMembershipStatus={updateMembershipStatus}
        resendRegistrationEmail={handleResendRegistrationEmail}
      />
    </>
  )
}

export default ProfileViewProvider;
