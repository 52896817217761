import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css'
import Error from "./app/_features/ErrorBoundary/Error";
import { ServiceWorkerProvider } from "./app/_features/ServiceWorker";
import App from './app/App';
import './index.css';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.NODE_ENV === 'development') {
  root.render(
    <React.StrictMode>
      <ServiceWorkerProvider>
        <App />
      </ServiceWorkerProvider>
    </React.StrictMode>
  )
} else {
  Bugsnag.start({
    apiKey: '41af1ebca3614114bde81e077de983b6',
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV
  })

  // @ts-ignore
  const BugsnagErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

  root.render(
    <React.StrictMode>
      <BugsnagErrorBoundary FallbackComponent={Error}>
        <ServiceWorkerProvider>
          <App />
        </ServiceWorkerProvider>
      </BugsnagErrorBoundary>
    </React.StrictMode>
  );
}


// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
