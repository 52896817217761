import { LockClosedIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../_helpers";
import parse from 'html-react-parser';

type PropType = {
  label: string;
  value: string;
  small?: boolean;
  setValue: (value: string) => void;
  children: React.ReactNode
  isRequired?: boolean
  vertical?: boolean
  disabled?: boolean
  isPrivate?: boolean
  className?: string
  infotext?: string
}

export const Selectfield = ({ label, value, setValue, children, small, isRequired, vertical, disabled, isPrivate, className, infotext }: PropType) => {
  return (
    <label
      className={classNames(
        'block',
        !vertical ? "sm:grid sm:grid-cols-3 sm:gap-4" : '',
        small ? 'sm:pt-2' : 'sm:pt-5',
        !infotext ? 'items-center' : '',
        className
      )}
    >
      <span
        className={classNames(
          "flex font-medium text-gray-700",
          small ? 'text-xs sm:pt-1' : 'text-sm',
          isRequired ? 'font-bold' : ''
        )}
      >
        {isPrivate && (
          <LockClosedIcon className={'w-4 h-4 mr-2 shrink-0'} />
        )}
        {label}
      </span>
      <span className="block mt-1 sm:col-span-2 sm:mt-0">
        {infotext && (
          <p className={'max-w-lg text-gray-700 text-sm pb-4'}>{parse(infotext)}</p>
        )}
        <select
          value={value}
          onChange={e => setValue(e.target.value)}
          required={isRequired}
          disabled={disabled}
          className={classNames(
            "block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:max-w-xs disabled:text-gray-400",
            small ? 'text-xs' : 'text-sm'
          )}
        >
          <option value={''}>Bitte wählen...</option>
          {children}
        </select>
      </span>
    </label>
  )
}
