import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { classNames } from "../_helpers";

export type TabProps = {
  id: string
  title: string
  status: string
  action: (args: any) => void
}

type PropTypes = {
  currentId: string | null
  items: TabProps[]
  mobileAction: (args: any) => void
  dark?: boolean
}

export const Tabs = ({currentId, items, mobileAction, dark}: PropTypes) => (
  <div className={classNames(
    'rounded-xl p-2 -mx-2 overflow-y-auto',
    dark ? 'bg-[#ECECEE]' : 'bg-primary-500 sm:bg-gray-200'
  )}>
    <div className="sm:hidden">
      <label htmlFor="memberships" className="sr-only">
        Select a tab
      </label>
      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
      <select
        id="tabs"
        name="tabs"
        value={currentId || ''}
        onChange={mobileAction}
        className="block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary"
      >
        {items.map((item) => (
          <option
            key={item.id}
            value={item.id}
          >{`${item.title}${item.status === 'requested' ? ' - angefragt' : ''}`}</option>
        ))}
      </select>
    </div>
    <div className="hidden sm:block whitespace-nowrap">
      <nav className="flex space-x-4" aria-label="Tabs">
        {items.map((item) => {
          if (!item.title) {
            return null
          }

          return (
            <button
              key={item.id}
              onClick={item.action}
              className={classNames(
                item.id === currentId
                  ? 'bg-primary-500 ring-1 ring-primary-400 text-primary-400'
                  : 'text-gray-500 hover:text-gray-700',
                'flex items-center gap-1 px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap'
              )}
              // aria-current={tab.current ? 'page' : undefined}
            >
              {item.title || ''}
              {item.status === 'requested' && <QuestionMarkCircleIcon className={'w-5 h-5'} />}
            </button>
          )
        })}
      </nav>
    </div>
  </div>
)
