import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import React, { useCallback, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { LOST_PASSWORD_URL } from "../../_constants/links";
import { classNames } from "../../_helpers";
import { useServiceWorker } from "../ServiceWorker";
import { SwUpdateDialog } from "../SwUpdateDialog";
import { useMyself } from "../UserContext";

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const { login } = useMyself() || {}
  const { isUpdateAvailable, updateAssets } = useServiceWorker() || {}

  const handleLogin = useCallback((e: React.FormEvent) => {
    e.preventDefault()
    if (login) {
      login(email, password, rememberMe)
    }
  }, [login, email, rememberMe, password])

  return (
    <div className="flex min-h-full">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <a
              href={`//${window.location.hostname.replace('app.', '')}:${window.location.port}`}
              className={'flex items-center gap-4 mb-8 text-gray-900 opacity-40'}
            >
              <ArrowUturnLeftIcon className={'w-4 h-4'} />
              Zurück zur Webseite
            </a>
            <img
              className="h-14 w-auto"
              src="/logo-rab.png"
              alt="Your Company"
            />
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 opacity-40">Jetzt anmelden</h2>
            <p className="mt-2 text-sm text-gray-600">
              &nbsp;
              <a href={`//${window.location.hostname.replace('app.', '')}:${window.location.port}/app/registrieren/`} className="font-medium text-primary hover:text-primary-100">
                Registrieren und Beckenrocker werden
              </a>
            </p>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleLogin} className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    E-Mail Adresse
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none sm:text-sm"
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Passwort
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      checked={rememberMe}
                      onChange={e => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                      Eingeloggt bleiben
                    </label>
                  </div>

                  <div className="text-sm">
                    <a target={'_blank'} rel={'noreferrer'} href={LOST_PASSWORD_URL} className="font-medium text-primary hover:text-primary-200">
                      Passwort vergessen?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none"
                  >
                    Anmelden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <ProgressiveImage
          src="/RAB-22-396.jpg"
          placeholder="/RAB-22-396-preview.jpg"
        >
          {(src, loading) => (
            <img
              src={src}
              alt="Rock am Beckenrand"
              className={classNames(
                'absolute inset-0 h-full w-full object-cover',
                loading ? 'blur' : ''
              )}
            />
          )}
        </ProgressiveImage>
      </div>

      {isUpdateAvailable && updateAssets && (
        <SwUpdateDialog />
      )}
    </div>
  )
}
