import { LogEntry } from "../../types/graphql/LogEntry";
import { LogItem } from "./LogItem";

type PropTypes = {
  entries: LogEntry[]
  fetching: boolean
}

const skeletonAmount = 6

export const UserLogsView = ({ entries, fetching }: PropTypes) => {
  return (
    <ul className="space-y-6">
      {fetching && entries.map((_, index) => (
        <LogItem
          key={index}
          isLast={index === skeletonAmount - 1}
        />
      ))}

      {!fetching && entries.map((logEntry, logEntryIdx) => (
        <LogItem
          key={logEntry.id}
          isLast={logEntryIdx === entries.length - 1}
          logEntry={logEntry}
        />
      ))}
    </ul>
  )
}
