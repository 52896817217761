import React, { useCallback, useMemo } from "react";
import { useMutation } from "urql";
import { addSectionLeadToCurrentSeasonMutation } from "../../../_lib/graphql/mutations";
import { Checkbox } from "../../../_ui/forms";
import { Section as SectionType } from "../../../types/graphql/Section";
import { User } from "../../../types/graphql/User";
import { useSnackbarPush } from "../../SnackbarContext/SnackbarContext";

type PropTypes = {
  section: SectionType,
  profile: User
}

export const Section = ({section, profile}: PropTypes) => {
  const { pushSuccess, pushError } = useSnackbarPush()
  const [, addSectionLeadToCurrentSeason] = useMutation(addSectionLeadToCurrentSeasonMutation)

  const handleChange = useCallback((checked: boolean) => {
    addSectionLeadToCurrentSeason({
      input: {
        userID: profile.id,
        sectionID: section.id,
        remove: !checked
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Speichern erfolgreich')
      }
    })
  }, [profile, section, addSectionLeadToCurrentSeason, pushSuccess, pushError])

  const checked = useMemo(() => {
    return (profile.sectionLeads || []).findIndex(s => s.id === section.id) >= 0
  }, [profile, section])

  return (
    <div className={'mb-2 col-span-2'}>
      <Checkbox
        bold
        label={section.title}
        checked={checked}
        setValue={handleChange}
      />
    </div>
  )
}
