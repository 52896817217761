export const REACT_MOUNT = 'app-mount';

const hostname = window.location.hostname
let currentHost = ''
switch (hostname) {
  // live
  case 'app.beckenrocker.de':
    currentHost = 'https://www.beckenrocker.de'
    break
  // staging
  case 'app.staging.beckenrocker.de':
    currentHost = 'https://staging.beckenrocker.de'
    break
  // dev
  default:
    currentHost = ['4443', '3000'].includes(window.location.port)
      ? 'https://rockambeckenrand.docker:4443'
      : 'http://rockambeckenrand.docker:8086'
}

export const GRAPHQL_ENDPOINT = process.env.NODE_ENV === 'development'
  ? `${currentHost}/beckenrocker`
  : `${currentHost}/beckenrocker`

const oAuthApiEndpoint = `${currentHost}/oauth2`
export const SILVERSTRIPE_OAUTH_API = {
  urls: {
    authorize: oAuthApiEndpoint + '/token/',
    refresh: oAuthApiEndpoint + '/refresh/'
  },
  data: {
    authorize: {
      grant_type: 'password'
    },
    refresh: {
      grant_type: 'refresh_token'
    }
  }
}

