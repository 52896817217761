import React from "react";

type PropTypes = {
  children: React.ReactNode
}

export const SlideOverDialogContent = ({children}: PropTypes) => (
  <div className="pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
    {children}
  </div>
)
