import { NoSymbolIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className={'flex w-full h-full items-center justify-center'}>
      <div className={'flex flex-col items-center text-center mb-32 px-12'}>
        <NoSymbolIcon className={'w-64 h-64 text-gray-200'} />
        <p className={'text-4xl text-gray-400 font-bold'}>
          Fehler 404
        </p>
        <p className={'text-xl text-gray-400'}>
          Die Seite konnte nicht gefunden werden
        </p>
        <p className={'text-xl text-gray-400'}>
          Zurück zur <Link to={'/'}>Startseite</Link>
        </p>
      </div>
    </div>
  )
}
