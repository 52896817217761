import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useMemo } from "react";
import { useMutation } from "urql";
import { classNames } from "../../../_helpers";
import { checkinUserMutation } from "../../../_lib/graphql/mutations";
import { SeasonCheckin } from "../../../types/graphql/SeasonCheckin";
import { SeasonCheckinItem } from "../../../types/graphql/SeasonCheckinItem";
import { useSnackbarPush } from "../../SnackbarContext/SnackbarContext";

type PropTypes = {
  item: SeasonCheckinItem
  seasonCheckin: SeasonCheckin
  seasonCheckinItems: SeasonCheckinItem[]
}

export const CheckinItem = ({ item, seasonCheckin, seasonCheckinItems }: PropTypes) => {
  const { pushSuccess, pushError } = useSnackbarPush()
  const [, checkinUser] = useMutation(checkinUserMutation)

  const checked = useMemo(() => {
    return seasonCheckinItems.findIndex(checkinItem => item.id === checkinItem.id) >= 0
  }, [item.id, seasonCheckinItems])

  const checkinTime = useMemo(() => {
    return seasonCheckinItems.find(checkinItem => item.id === checkinItem.id)?.checkinTime || null
  }, [item.id, seasonCheckinItems])

  const handleCheckin = useCallback(() => {
    if (!seasonCheckin) {
      return
    }

    checkinUser({
      input: {
        userID: seasonCheckin.user?.id || '',
        checkinItemID: item.id,
        seasonCheckinID: seasonCheckin.id
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Check-In erfolgreich')
      }
    })
  }, [checkinUser, item.id, pushError, pushSuccess, seasonCheckin])

  return (
    <li className={'flex py-2 items-center gap-4 cursor-pointer'} onClick={handleCheckin}>
      <div
        className={classNames(
          'flex items-center justify-center rounded-full w-6 h-6',
          checked ? 'bg-lime-300 text-black' : 'bg-red-500 text-white'
        )}
      >
        {checked ? (
          <CheckIcon className={'w-4 h-4'} />
        ) : (
          <XMarkIcon className={'w-4 h-4'} />
        )}
      </div>
      {item.title}
      {checkinTime && (
        <div className={'ml-auto text-xs'}>
          {new Date(checkinTime).toLocaleString('de', {
            weekday: 'long',
            year: "numeric",
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          })} Uhr
        </div>
      )}
    </li>
  )
}
