import { useCallback, useState } from "react";
import { useMutation } from "urql";
import { APP_STATUS_LABEL_ASSIGN } from "../../_constants/permissions";
import { changeStatusLabelForMembershipMutation } from "../../_lib/graphql/mutations";
import { Membership } from "../../types/graphql/Membership";
import { UserGroup } from "../../types/graphql/UserGroup";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";
import { useMyself } from "../UserContext";
import { AddMembershipDialog } from "./AddStatusLabelDialog";
import { StatusLabelView } from "./StatusLabelView";

type PropTypes = {
  membership?: Membership
  userGroup?: UserGroup
}

export const StatusLabel = ({ membership, userGroup }: PropTypes) => {
  const { canAccess } = useMyself() || {}
  const { pushSuccess, pushError } = useSnackbarPush()
  const [dialogOpen, setDialogOpen] = useState(false)

  const [, changeStatusLabelForMembership] = useMutation(changeStatusLabelForMembershipMutation)

  const handleSubmit = useCallback((statusLabelID: string, checked: boolean) => {
    if (canAccess && canAccess(APP_STATUS_LABEL_ASSIGN) && membership && userGroup) {
      changeStatusLabelForMembership({
        input: {
          membershipID: membership?.id,
          statusLabelID: statusLabelID,
          active: checked
        }
      }).then(res => {
        if (res.error) {
          pushError(res.error.message)
        } else {
          pushSuccess('Speichern erfolgreich')
        }
      })
    }
  }, [canAccess, membership, userGroup, changeStatusLabelForMembership, pushError, pushSuccess])

  return (
    <>
      <StatusLabelView
        statusLabels={membership?.statusLabels || []}
        openDialog={() => setDialogOpen(true)}
        hasAvailableLabels={(userGroup?.statusLabels?.length || 0) > 0}
      />

      {canAccess && canAccess(APP_STATUS_LABEL_ASSIGN) && (
        <AddMembershipDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          statusLabels={membership?.statusLabels || []}
          availableStatus={userGroup?.statusLabels || []}
          handleSubmit={handleSubmit}
          fetching={false}
        />
      )}
    </>
  )
}
