/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from "react-router-dom";
import { useSchedule } from "../_features/ScheduleContext";
import { useMyself } from "../_features/UserContext";
import { classNames } from "../_helpers";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { SlideOver } from "./SlideOver";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { isDebugUser } = useMyself() || {}
  const { season } = useSchedule() || {}

  const location = useLocation()

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [location])

  const isStaging = useMemo(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)

    if (urlParams.get('banner') === 'true') {
      return true
    }

    return window.location.host.includes('staging.beckenrocker')
      && urlParams.get('banner') !== 'false'
  }, [])

  const isSwitchedSeason = useMemo(() => {
    return season && !season.isLatest
  }, [season])

  return (
    <>
      <div className={classNames(
        'flex h-full',
        (isStaging || isDebugUser || isSwitchedSeason) ? 'pt-7' : ''
      )}>
        {isStaging && (
          <div className={'fixed flex top-0 left-0 w-screen h-7 bg-red-600 items-center justify-center uppercase font-bold text-white tracking-widest'}>
            Test-system
          </div>
        )}
        {isDebugUser && (
          <div className={'fixed flex top-0 left-0 w-screen h-7 bg-red-600 items-center justify-center uppercase font-bold text-white tracking-widest'}>
            DEBUG-USER
          </div>
        )}
        {isSwitchedSeason && (
          <div className={'fixed flex gap-4 top-0 left-0 w-screen h-7 bg-yellow-400 items-center justify-center uppercase font-bold text-white tracking-widest'}>
            <ExclamationTriangleIcon className={'w-5 h-5'} />
            {season?.title}
            <ExclamationTriangleIcon className={'w-5 h-5'} />
          </div>
        )}
        {/* Narrow sidebar */}
        <Sidebar
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />

        {/* Content area */}
        <div className="flex flex-1 flex-col overflow-hidden">
          <Header setMobileMenuOpen={setMobileMenuOpen} />

          {/* Main content */}
          <div className="flex flex-1 items-stretch overflow-hidden lg:pb-0">
            {children}
          </div>
        </div>
      </div>

      <SlideOver />
    </>
  )
}
