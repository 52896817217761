import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Tabs } from "../../_ui/Tabs";
import { Membership } from "../../types/graphql/Membership";

type PropTypes = {
  memberships?: Membership[]
}

export const MembershipTabs = ({memberships}: PropTypes) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const groupID = useMemo(() => {
    if (searchParams) {
      return searchParams.get('group')
    }

    return null
  }, [searchParams])

  const changeGroup = useCallback((selectedGroupID: string) => {
    if (selectedGroupID === groupID) {
      setSearchParams(prev => {
        prev.delete('group')
        return prev
      })
    } else {
      setSearchParams(prev => {
        prev.set('group', selectedGroupID)
        return prev
      })
    }
  }, [setSearchParams, groupID])

  if (!memberships) {
    return null;
  }


  if (memberships?.length === 0) {
    return (
      <div className={'text-gray-500 text-sm'}>
        Keine Gruppen vorhanden
      </div>
    )
  }

  return (
    <Tabs
      currentId={groupID}
      mobileAction={(e: any) => changeGroup(e.target.value || '')}
      items={memberships.reduce((acc: any, item: Membership) => {
        return [
          ...acc,
          {
            id: item.userGroup?.id,
            title: item.userGroup?.title || '',
            action: (e: any) => {
              e.preventDefault()
              changeGroup(item.userGroup?.id || '')
            },
            status: item.status
          }
        ]
      }, [])
    } />
  )
}
