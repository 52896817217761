import React from "react";
import Skeleton from "react-loading-skeleton";
import { Fieldgroup } from "../../../_ui/forms";
import { User } from "../../../types/graphql/User";

type PropTypes = {
  loading?: boolean
  profile?: User
}

export const PersonalData = ({loading, profile}: PropTypes) => {
  return (
    <Fieldgroup label={'Persönliche Daten'}>
      <div className={'grid text-sm space-y-2 sm:space-y-0 sm:grid-cols-2 sm:gap-2'}>
        <div>
          <p className={'font-bold'}>Adresse</p>
          {!loading && profile?.street ? (
            <p>{profile.street}</p>
          ) : (
            <Skeleton height={16} width={100} style={{marginBottom: 4}} />
          )}
          {!loading && profile?.zip && profile?.city ? (
            <p>{profile.zip} {profile.city}</p>
          ) : (
            <Skeleton height={16} width={100} />
          )}
        </div>
        <div>
          <p className={'font-bold'}>Kontakt</p>
          {profile?.email ? (
            <p>{profile.email}</p>
          ) : (
            <Skeleton height={16} width={100} style={{marginBottom: 4}} />
          )}
          {profile?.phone && (
            <p>{profile?.phone}</p>
          )}
        </div>
      </div>
    </Fieldgroup>
  )
}
