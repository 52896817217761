import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { classNames } from "../../_helpers";
import { deleteAvailabilitySlotMutation } from "../../_lib/graphql/mutations";
import { Button } from "../../_ui/Button";
import { Dialog } from "../../_ui/Dialog";
import { AvailabilitySlot } from "../../types/graphql/AvailabilitySlot";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";

type PropTypes = {
  slot: AvailabilitySlot
  readonly: boolean
}

export const EditAvailabilityView = ({ slot, readonly }: PropTypes) => {
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { pushSuccess, pushError } = useSnackbarPush()
  const navigate = useNavigate()

  const [, deleteAvailabilitySlot] = useMutation(deleteAvailabilitySlotMutation)

  const handleDelete = useCallback(() => {
    deleteAvailabilitySlot({
      id: slot.id,
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Löschen erfolgreich')
      }
    })
  }, [deleteAvailabilitySlot, pushError, pushSuccess, slot.id])

  return (
    <div className={classNames(
      'w-full grid text-sm',
      readonly ? 'grid-cols-[70px,20px,70px,1fr] md:grid-cols-[1fr,70px,20px,70px]' : 'grid-cols-[70px,20px,70px,1fr] md:grid-cols-[1fr,70px,20px,70px,60px]'
    )}>
      <div className={'col-span-4 md:col-span-1'}>{slot.sectionRole?.title}</div>
      <div className={'md:text-center'}>
        {new Date(slot.begin?.replace(/-/g, '/')).toLocaleTimeString('de-DE', {
          hour: '2-digit',
          minute: '2-digit',
        })} Uhr
      </div>
      <div className={'text-center'}>&ndash;</div>
      <div className={classNames(
        'text-center',
      )}>
        {new Date(slot.end?.replace(/-/g, '/')).toLocaleTimeString('de-DE', {
          hour: '2-digit',
          minute: '2-digit',
        })} Uhr
      </div>

      {!readonly && (
        <>
          <div className={'grid grid-cols-2 col-span-4 gap-2 mt-1 md:col-span-1 md:mt-0 md:pl-2'}>
            <button
              onClick={() => navigate(`./${slot.id}/`)}
              className={'flex gap-2 items-center justify-center py-1 bg-primary rounded-sm text-white md:py-0 md:bg-transparent md:text-black'}
            >
              <PencilIcon className={'w-4 h-4'} />
              <span className={'block md:hidden'}>Bearbeiten</span>
            </button>
            <button
              onClick={() => setDeleteOpen(true)}
              className={'flex gap-2 items-center justify-center py-1 bg-red-700 rounded-sm text-white md:py-0 md:bg-transparent md:text-black'}
            >
              <TrashIcon className={'w-4 h-4'} />
              <span className={'block md:hidden'}>Löschen</span>
            </button>
          </div>

          <Dialog title={'Löschen bestätigen'} open={deleteOpen} onClose={() => setDeleteOpen(false)}>
            <p className={'mb-4'}>
              Soll der Eintrag wirklich gelöscht werden?
            </p>
            <div className={'grid grid-cols-2 gap-2'}>
              <Button onClick={() => setDeleteOpen(false)}>Zurück</Button>
              <Button onClick={handleDelete} color={"primary"}>Ja, löschen</Button>
            </div>
          </Dialog>
        </>
      )}
    </div>
  )
}
