import { Dispatch, SetStateAction } from "react";
import { DATEOPTIONS, TIMEOPTIONS } from "../../_constants/datetime";
import { Note } from "../../types/graphql/Note";

type PropTypes = {
  notes: Note[]
  message: string
  setMessage: Dispatch<SetStateAction<string>>
  submitNote: () => void
  fetching: boolean
}

export const MembershipNotesView = ({ notes, message, setMessage, submitNote, fetching }: PropTypes) => {
  return (
    <div className="p-4">
      <h5 className={'font-medium text-lg'}>Profil-Noitzen</h5>
      <p className={'text-xs mb-2'}>Diese Notizen betreffen den Benutzer und sollen keinen inhaltlichen Gruppenbezug haben.</p>

      <div className={'w-96 h-4 -mx-4 mb-4 bg-primary'} />

      <div className={'flex flex-col gap-4 text-sm'}>
        <div className={'flex flex-col'}>
          <label className={'mb-1'}>Neue Notiz schreiben:</label>
          <textarea
            rows={4}
            name="comment"
            id="comment"
            value={message}
            disabled={fetching}
            onChange={e => setMessage(e.target.value)}
            className={'px-3 py-2 border border-gray-200 bg-gray-100 rounded-lg text-sm'}
          />
          <div className={'flex justify-end'}>

            <button
              onClick={submitNote}
              disabled={fetching}
              className="inline-flex justify-center mt-2 ml-auto rounded-md border border-transparent bg-primary px-2 py-1 text-base text-white shadow-sm hover:bg-primary focus:outline-none sm:text-sm disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              Speichern
            </button>
          </div>
        </div>

        <hr />

        {notes.map(note => note && (
          <div key={note.id} className={'p-3 border border-gray-200 bg-gray-100 rounded-lg'}>
            <h6 className={'mb-1 font-medium leading-[16px]'}>
              {`${note.author?.firstName || ''} ${note.author?.surname || ''}`}<br />
              <span className={'text-[12px]'}>am {new Date(note.created).toLocaleDateString('de-DE', DATEOPTIONS as any)} um {new Date(note.created).toLocaleTimeString('de-DE', TIMEOPTIONS as any)} Uhr
              </span>
            </h6>
            {note.message}
          </div>
        ))}
      </div>
    </div>
  )
}
