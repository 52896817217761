import { useCallback, useRef, useState } from "react";
import { useMutation } from "urql";
import { addSeasonNoticeForUserMutation } from "../../_lib/graphql/mutations";
import { Textareafield } from "../../_ui/forms/Textareafield";
import { User } from "../../types/graphql/User";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";

type PropTypes = {
  user: User
}

export const EditUserSeasonNotice = ({ user }: PropTypes) => {
  const { pushSuccess, pushError } = useSnackbarPush()
  const noticeRef = useRef(user.seasonNotice || '')
  const [notice, setNotice] = useState(() => user.seasonNotice || '')
  const [, addSeasonNoticeForUser] = useMutation(addSeasonNoticeForUserMutation)

  const handleUpdate = useCallback((value: string) => {
    addSeasonNoticeForUser({
      input: {
        userID: user.id,
        notice: value
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Notiz gespeichert')
      }
    })
  }, [addSeasonNoticeForUser, pushError, pushSuccess, user.id])

  const changeValue = useCallback((value: string) => {
    setNotice(value)
    noticeRef.current = value

    setTimeout(() => {
      if (noticeRef.current === value && user.seasonNotice !== value) {
        handleUpdate(value)
      }
    }, 1000)
  }, [handleUpdate, user.seasonNotice])

  return (
    <>
      <Textareafield
        label={'Notiz'}
        value={notice}
        setValue={changeValue}
      />
      <p className={'text-xs pt-4'}><strong>Hinweis:</strong> Diese Notiz ist für alle sichtbar, die Zugriff zum Profil haben. Dies schließt die Bereichtsleitung sowie Schichtleitung mit ein.</p>
    </>
  )
}
