import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useQuery } from "urql";
import { readOneUserQuery } from "../../_lib/graphql/queries";
import { DialogHeader } from "../../_ui/DialogHeader";
import { EditProfileImage } from "./components/EditProfileImage";
import { EditUserForm } from "./EditUserForm";

type PropTypes = {
  userID: string
  open: boolean
  hideNotificationSettings?: boolean
  onClose: () => void
}

export const EditUser = ({userID, open, onClose, hideNotificationSettings}: PropTypes) => {
  const [userResult] = useQuery({
    query: readOneUserQuery,
    variables: {
      input: {
        userID
      }
    },
    pause: !userID || !open
  })

  if (userID === '') {
    return null
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden transform rounded-lg bg-white w-full px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6">
                <div>
                  <DialogHeader title={'Benutzer bearbeiten'} onClose={onClose} />

                  <EditProfileImage user={userResult.data?.readOneUser} />

                  <EditUserForm
                    user={userResult.data?.readOneUser}
                    hideNotificationSettings={hideNotificationSettings}
                  />

                  <div className={'mt-4 pt-4 border-t border-gray-200 text-sm text-gray-400'}>
                    <strong>Hinweis:</strong> Änderungen werden automatisch gespeichert
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
