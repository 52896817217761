import { XMarkIcon } from "@heroicons/react/20/solid";

type PropTypes = {
  title: string
  onClose: () => void
}

export const DialogHeader = ({title, onClose}: PropTypes) => (
  <>
    <div className={'flex justify-between'}>
      <h3 className={'text-lg font-medium text-gray-900'}>{title}</h3>
      <button onClick={onClose}>
        <XMarkIcon className={'w-5 h-5'} />
      </button>
    </div>

    <div className={'h-4 mt-3 mb-4 -mx-6 bg-primary-300 sm:mb-0'}></div>
  </>
)
