import React from "react";
import { DATEOPTIONS } from "../../_constants/datetime";
import {
  APP_VIEW_MEMBERSHIPS,
  APP_VIEW_USER_CHECKIN,
  APP_VIEW_USER_LEADS_SECTION_ROLES,
  APP_VIEW_USER_PERSONALDATA
} from "../../_constants/permissions";
import { ProfileHeader } from "../../_ui/ProfileHeader";
import { Season } from "../../types/graphql/Season";
import { User } from "../../types/graphql/User";
import { useMyself } from "../UserContext";
import { Checkin } from "./components/Checkin";
import { LeadsAndSections } from "./components/LeadsAndSections";
import { Memberships } from "./components/Memberships";
import { PersonalData } from "./components/PersonalData";
import { Schedule } from "./components/Schedule";

type PropTypes = {
  profile?: User
  fetching: boolean
  stale: boolean
  editProfile?: () => void,
  openHistory?: () => void,
  seasons: Season[],
  updateMembershipStatus: (membershipID: string, status: string) => void
  resendRegistrationEmail: () => void
}

export const ProfileView = ({profile, fetching, stale, editProfile, openHistory, seasons, updateMembershipStatus, resendRegistrationEmail}: PropTypes) => {
  const { canAccess } = useMyself() || {}

  return (
    <>
      <ProfileHeader
        fetching={!profile?.firstName || !profile?.surname}
        status={profile?.status}
        name={`${profile?.firstName} ${profile?.surname}`}
        nickname={profile?.nickname}
        position={profile?.birthday ? new Date(profile?.birthday as string).toLocaleDateString('de-DE', DATEOPTIONS as any) : undefined}
        minorAge={profile?.minorAge}
        email={profile?.email}
        editProfile={editProfile}
        openHistory={openHistory}
        userNoteAmount={profile?.userNoteAmount || 0}
        userID={profile?.id || ''}
        profileImage={profile?.profileImageUrl || ''}
        resendRegistrationEmail={resendRegistrationEmail}
      />
      <div className={'space-y-2 divide-y divide-gray-200'}>
        {canAccess && (canAccess(APP_VIEW_USER_PERSONALDATA) || profile?.hasLeaderPermissionsForUser) && (
          <div className={'px-4 pb-4 sm:px-8'}>
            <PersonalData
              loading={stale || profile === undefined}
              profile={profile}
            />
          </div>
        )}

        <div className={'px-4 py-4 sm:px-8'}>
          <Schedule
            loading={stale || profile === undefined}
            user={profile}
          />
        </div>

        {canAccess && (canAccess(APP_VIEW_USER_CHECKIN) || profile?.hasLeaderPermissionsForUser) && (
          <div className={'px-4 py-4 sm:px-8'}>
            <Checkin user={profile} />
          </div>
        )}

        {/* TODO: schichten ausgeben */}
        {/*<div className={'px-4 py-4 sm:px-8'}>*/}
        {/*  <h3 className={'font-medium pb-4'}>Schichten</h3>*/}
        {/*</div>*/}

        {profile && canAccess && (canAccess(APP_VIEW_USER_LEADS_SECTION_ROLES) || profile?.hasLeaderPermissionsForUser) && (
          <div className={'px-4 py-4 sm:px-8'}>
            <LeadsAndSections
              profile={profile}
              seasons={seasons}
            />
          </div>
        )}

        {profile && canAccess && (canAccess(APP_VIEW_MEMBERSHIPS) || profile?.hasLeaderPermissionsForUser) && (
          <div className={'px-4 py-4 sm:px-8'}>
            <Memberships
              loading={fetching || stale}
              profile={profile}
              updateMembershipStatus={updateMembershipStatus}
            />
          </div>
        )}
      </div>
    </>
  )
}
