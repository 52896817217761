export const IS_SECTION_LEAD = 'IS_SECTION_LEAD'
export const IS_SECTION_ROLE_LEAD = 'IS_SECTION_ROLE_LEAD'
export const APP_ADMIN = 'APP_ADMIN'
export const APP_ALL_USER_LIST = 'APP_ALL_USER_LIST'
export const APP_CREW_MANAGEMENT = 'APP_CREW_MANAGEMENT'
export const APP_CREW_SCHEDULE = 'APP_CREW_SCHEDULE'
export const APP_LEAD_SCHEDULE = 'APP_LEAD_SCHEDULE'
export const __PARTIAL__APP_READONLY_SCHEDULE = '__PARTIAL__APP_READONLY_SCHEDULE'
export const __PARTIAL__APP_CREW_SCHEDULE = '__PARTIAL__APP_CREW_SCHEDULE'
export const __PARTIAL__APP_LEAD_SCHEDULE = '__PARTIAL__APP_LEAD_SCHEDULE'
export const APP_EDIT_SECTION_LEAD = 'APP_EDIT_SECTION_LEAD'
export const APP_USER_NOTES = 'APP_USER_NOTES'
export const APP_STATUS_LABEL_ASSIGN = 'APP_STATUS_LABEL_ASSIGN'
export const APP_EDIT_USER = 'APP_EDIT_USER'
export const APP_VIEW_LOGS = 'APP_VIEW_LOGS'
export const APP_EDIT_MEMBERSHIPS = 'APP_EDIT_MEMBERSHIPS'
export const APP_VIEW_MEMBERSHIPS = 'APP_VIEW_MEMBERSHIPS'
export const APP_MESSAGES = 'APP_MESSAGES'
export const APP_VIEW_USER = 'APP_VIEW_USER'
export const APP_VIEW_USER_PERSONALDATA = 'APP_VIEW_USER_PERSONALDATA'
export const APP_VIEW_USER_CHECKIN = 'APP_VIEW_USER_CHECKIN'
export const APP_VIEW_USER_LEADS_SECTION_ROLES = 'APP_VIEW_USER_LEADS_SECTION_ROLES'
export const APP_ACCEPT_MEMBERSHIPS = 'APP_ACCEPT_MEMBERSHIPS'
export const APP_STATUS_LABEL_EDIT = 'APP_STATUS_LABEL_EDIT'
export const APP_EDIT_SECTIONROLE_DESCRIPTION = 'APP_EDIT_SECTIONROLE_DESCRIPTION'
export const APP_ADD_SECTION_TO_USER = 'APP_ADD_SECTION_TO_USER'
export const APP_SEARCH_USER = 'APP_SEARCH_USER'
export const __PARTIAL__APP_SEARCH_USER = '__PARTIAL__APP_SEARCH_USER'
export const APP_CHECKIN = 'APP_CHECKIN'
export const APP_SLOTCHECKIN = 'APP_SLOTCHECKIN'
export const __PARTIAL__APP_SLOTCHECKIN = '__PARTIAL__APP_SLOTCHECKIN'
export const APP_RESEND_REGISTRATION_EMAIL = 'APP_RESEND_REGISTRATION_EMAIL'
export const APP_EXPORT_LISTS = 'APP_EXPORT_LISTS'
export const APP_GUEST_LIST_VIEW = 'APP_GUEST_LIST_VIEW'
export const APP_GUEST_LIST_EDIT = 'APP_GUEST_LIST_EDIT'
export const APP_CHECKIN_PASSES = 'APP_CHECKIN_PASSES'
export const APP_EDIT_PASSES = 'APP_EDIT_PASSES'
export const APP_VIEW_PASSES = 'APP_VIEW_PASSES'
export const APP_SWITCH_SEASON = 'APP_SWITCH_SEASON'
export const APP_USER_WORKLOAD = 'APP_USER_WORKLOAD'
export const APP_CONTROLLISTS = 'APP_CONTROLLISTS'
export const APP_SCHEDULE_SEASON_NOTICE = 'APP_SCHEDULE_SEASON_NOTICE'
