// @ts-ignore
import { SnackbarProvider } from 'notistack';
import React from 'react';
import './Snackbar.css'
import SnackbarContext from './SnackbarContext';

export const SnackbarContextProvider = ({ children }: {children: React.ReactNode}) => {
  return (
    <SnackbarProvider maxSnack={10} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}>
      <SnackbarContext>
        {children}
      </SnackbarContext>
    </SnackbarProvider>
  );
}

export default SnackbarContextProvider;
