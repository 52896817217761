import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../_features/Breadcrumbs";
import useWindowDimensions from "../../_hooks/windoDimensions";
import { BreadcrumbPage } from "../../types/BreadcrumbPage";
import { ListColumn } from "./components/ListColumn";
import { MainColumn } from "./components/MainColumn";

type PropTypes = {
  left: React.ReactNode,
  children?: React.ReactNode
  breadcrumbPages?: BreadcrumbPage[]
}

const BREAKPOINT = 1024

export const TwoColumn = ({left, breadcrumbPages, children}: PropTypes) => {
  const params = useParams()
  const { width } = useWindowDimensions();

  return (
    <>
      {(width >= BREAKPOINT || params['*'] === '') && (
        <ListColumn>
          {left}
        </ListColumn>
      )}
      {(width >= BREAKPOINT || params['*'] !== '') && (
        <MainColumn>
          {width < BREAKPOINT && (
            <Breadcrumbs pages={breadcrumbPages || []} />
          )}
          {children}
        </MainColumn>
      )}
    </>
  )
}
