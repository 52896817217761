import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Dispatch, SetStateAction, useState } from 'react'
import { classNames } from "../../_helpers";
import { User } from "../../types/graphql/User";

type PropTypes = {
  user: User[],
  selectedPerson: User | null
  setSelectedPerson: Dispatch<SetStateAction<User | null>> | ((user: User) => void);
}

export const AddUserInput = ({user, selectedPerson, setSelectedPerson}: PropTypes) => {
  const [query, setQuery] = useState('')

  const filteredPeople =
    query === ''
      ? user
      : user.filter((user) => {
        return (`${user.firstName} ${user.surname}`).toLowerCase().includes(query.toLowerCase())
      })

  const sortFunction = (a: User, b: User) => {
    const aName = `${a.firstName} ${a.surname}`.toLowerCase()
    const bName = `${b.firstName} ${b.surname}`.toLowerCase()

    if (aName < bName) return -1
    if (aName > bName) return 1

    return 0
  }

  return (
    <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary focus:outline-none sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(user: User) => user ? `${user.firstName} ${user.surname}` : ''}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.sort(sortFunction).map((user) => (
              <Combobox.Option
                key={user.id}
                value={user}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-8 pr-4',
                    active ? 'bg-primary text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{`${user.firstName} ${user.surname}`}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          active ? 'text-white' : 'text-primary'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
