import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { DATEOPTIONS } from "../../_constants/datetime";
import { updateAvailabilitySlotMutation } from "../../_lib/graphql/mutations";
import { readOneAvailabilitySlotQuery } from "../../_lib/graphql/queries";
import { Button } from "../../_ui/Button";
import { Textfield } from "../../_ui/forms";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";

export const EditForm = () => {
  const { availabilityID } = useParams()
  const { pushSuccess, pushError } = useSnackbarPush()
  const [date, setDate] = useState('')
  const [begin, setBegin] = useState('')
  const [end, setEnd] = useState('')
  const navigate = useNavigate()

  const [, updateAvailabilitySlot] = useMutation(updateAvailabilitySlotMutation)

  const [availabilitySlotResult] = useQuery({
    query: readOneAvailabilitySlotQuery,
    variables: {
      input: {
        id: availabilityID
      }
    },
    pause: !availabilityID,
  })

  const handleSubmit = useCallback(() => {
    updateAvailabilitySlot({
      input: {
        id: availabilityID,
        begin,
        end
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Speichern erfolgreich')
        navigate('../')
      }
    })
  }, [availabilityID, begin, end, navigate, pushError, pushSuccess, updateAvailabilitySlot])

  useEffect(() => {
    if (availabilitySlotResult.data?.readOneAvailabilitySlot) {
      setDate(availabilitySlotResult.data?.readOneAvailabilitySlot?.begin?.slice(0, 10))
    }
  }, [availabilitySlotResult])

  useEffect(() => {
    if (availabilitySlotResult.data?.readOneAvailabilitySlot) {
      setBegin(availabilitySlotResult.data?.readOneAvailabilitySlot?.begin?.slice(11, 16))
    }
  }, [availabilitySlotResult])

  useEffect(() => {
    if (availabilitySlotResult.data?.readOneAvailabilitySlot) {
      setEnd(availabilitySlotResult.data?.readOneAvailabilitySlot?.end?.slice(11, 16))
    }
  }, [availabilitySlotResult])

  return (
    <div>
      <form onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}>
        <div className={'grid grid-cols-2 gap-x-2 items-center'}>
          <h4 className={'col-span-2 mb-4 text-lg font-bold'}>Bestehende Verfügbarkeit bearbeiten</h4>
          <div className={'col-span-2 text-sm'}>
            <span className={'font-medium'}>Datum:</span> {date ? new Date(date).toLocaleDateString('de-DE', DATEOPTIONS as any) : ''}
          </div>
          <div>
            <Textfield hasHourMask small vertical label={'Beginn'} value={begin} setValue={value => setBegin(value)} />
          </div>

          <div>
            <Textfield hasHourMask small vertical label={'Ende'} value={end} setValue={value => setEnd(value)} />
          </div>
        </div>

        <div className={'flex justify-end mt-4 gap-2'}>
          <Button>
            <Link to={'../'} className={'flex items-center'}>
              {/*<ChevronLeftIcon className={'w-6 h-6 -translate-x-1/3'} />*/}
              zurück
            </Link>
          </Button>
          <Button type={"submit"} color={"primary"}>
            Speichern
          </Button>
        </div>
      </form>

    </div>
  )
}
