import {
  ArrowDownTrayIcon,
  BookmarkIcon,
  ChatBubbleBottomCenterIcon,
  ClipboardDocumentIcon,
  ClockIcon,
  CogIcon,
  HomeIcon, IdentificationIcon,
  LightBulbIcon,
  MusicalNoteIcon,
  QueueListIcon,
  RectangleGroupIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import React, { useMemo } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { classNames } from "../_helpers";
import { NavigationItem } from "../types/NavigationItem";

type PropTypes = {
  item: NavigationItem,
  mobile?: boolean,
}

export const MenuItem = ({item, mobile}: PropTypes) => {
  const matchObject = useMatch(`${item.href}*`)
  const match = matchObject !== null && (matchObject.pathname === '/' || matchObject.pathnameBase !== '/')

  const icon = useMemo(() => {
    switch (item.icon) {
      case 'ArrowDownTrayIcon':
        return {component: ArrowDownTrayIcon}
      case 'ClipboardDocumentIcon':
        return {component: ClipboardDocumentIcon}
      case 'MusicalNoteIcon':
        return {component: MusicalNoteIcon}
      case 'ClockIcon':
        return {component: ClockIcon}
      case 'CogIcon':
        return {component: CogIcon}
      case 'HomeIcon':
        return {component: HomeIcon}
      case 'LightBulbIcon':
        return {component: LightBulbIcon}
      case 'QueueListIcon':
        return {component: QueueListIcon}
      case 'BookmarkIcon':
        return {component: BookmarkIcon}
      case 'RectangleGroupIcon':
        return {component: RectangleGroupIcon}
      case 'UsersIcon':
        return {component: UsersIcon}
      case 'ChatBubbleBottomCenterIcon':
        return {component: ChatBubbleBottomCenterIcon}
      case 'IdentificationIcon':
        return {component: IdentificationIcon}
      default:
        return {component: React.Fragment}
    }
  }, [item.icon])

  if (mobile) {
    return (
      <NavLink
        key={item.name}
        to={item.href}
        className={({isActive, isPending}) => classNames(
          (isActive || isPending || match)
            ? 'bg-primary-400 text-white'
            : 'text-white hover:bg-primary-400 hover:text-white',
          'group py-2 px-3 rounded-md flex items-center text-sm font-medium'
        )}
        // aria-current={item.current ? 'page' : undefined}
      >
        <icon.component
          className={classNames(
            // item.current ? 'text-white' : 'text-indigo-300 group-hover:text-white',
            'mr-3 h-6 w-6'
          )}
          aria-hidden="true"
        />
        <span>{item.name}</span>
      </NavLink>
    )
  }

  return (
    <NavLink
      key={item.name}
      to={item.href}
      className={({ isActive, isPending }) => classNames(
        (isActive || isPending || match) ? 'bg-primary-400 text-white' : 'text-white hover:bg-primary-400 hover:text-white',
        'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium text-white'
      )}
      // aria-current={isActive ? 'page' : undefined}
    >
      <icon.component
        className={classNames(
          // isActive ? 'text-white' : 'text-white group-hover:text-white',
          'h-6 w-6'
        )}
        aria-hidden="true"
      />
      <span className="mt-2 text-center text-[11px]">
        {item.name}
      </span>
    </NavLink>
  )
}
