import React from 'react'
import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline'

const Error = () => {

  return (
    <div className={'w-full max-w-xl mx-auto lg:fixed lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 p-4'}>
      {/*<Rounded>*/}
        <div className={'p-4 md:px-8 md:py-5 bg-gray-300 shadow-2xl rounded-xl ring-1 ring-gray-400'}>
          <div className={'grid gap-2 lg:gap-8 lg:grid-cols-4 items-center'}>
            <div className={'flex justify-center w-full'}>
              <WrenchScrewdriverIcon className={'max-w-[100px] text-gray-600'} />
            </div>
            <div className={'lg:col-span-3 py-4'}>
              <h1 className={'text-2xl text-center text-primary pb-3 lg:text-left'}>
                Ups, das sollte nicht passieren...
              </h1>
              <div className={"text-md"}>
                Versuche die Seite einmal neu zu laden oder komm später wieder.
              </div>
              <div className={'flex gap-4 pt-5 justify-end'}>
                <button
                  className={'inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none sm:text-sm disabled:bg-gray-300 disabled:cursor-not-allowed'}
                  onClick={() => window.location = `//${window.location.host}`}
                >
                  Zurück zur Startseite
                </button>

                <button
                  className={'inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none sm:text-sm disabled:bg-gray-300 disabled:cursor-not-allowed'}
                  onClick={() => window.location.reload()}
                >
                  Neu laden
                </button>
              </div>
            </div>
          </div>
        </div>
      {/*</Rounded>*/}
    </div>
  );
}

Error.propTypes = {}

export default Error;
