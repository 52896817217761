import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { classNames } from "../../_helpers";
import { LogEntry } from "../../types/graphql/LogEntry";

type PropTypes = {
  logEntry?: LogEntry
  isLast: boolean
}

export const LogItem = ({ logEntry, isLast }: PropTypes) => {

  const dateFormat = useMemo(() => {
    if (!logEntry) {
      return
    }

    const heute = new Date();
    heute.setHours(0, 0, 0, 0);

    // Datum vor 7 Tagen
    const vor7Tagen = new Date(heute);
    vor7Tagen.setDate(heute.getDate() - 7);

    // Eingabedatum (mit 00:00:00 Uhr)
    const eingabeDatum = new Date(logEntry.date);
    eingabeDatum.setHours(0, 0, 0, 0);

    // Überprüfen, ob das Datum in den letzten 7 Tagen liegt
    if (eingabeDatum >= vor7Tagen && eingabeDatum <= heute) {
      const time = (new Date(logEntry.date)).toLocaleString('de-DE', {
        hour: "2-digit",
        minute: '2-digit'
      }) + ' Uhr'

      if (eingabeDatum.getDate() === heute.getDate()) {
        return `Heute, ${time}`
      }

      const gestern = new Date();
      gestern.setDate(heute.getDate() - 1);
      if (eingabeDatum.getDate() === gestern.getDate()) {
        return `Gestern, ${time}`
      }

      const vorgestern = new Date();
      vorgestern.setDate(heute.getDate() - 2);
      if (eingabeDatum.getDate() === vorgestern.getDate()) {
        return `Vorgestern, ${time}`
      }

      return (new Date(logEntry.date)).toLocaleString('de-DE', {
        weekday: 'long',
        hour: "2-digit",
        minute: '2-digit'
      }) + ' Uhr'
    }

    return (new Date(logEntry.date)).toLocaleString('de-DE', {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: '2-digit'
    }) + ' Uhr'
  }, [logEntry])

  if (!logEntry) {
    return (
      <li className="relative flex gap-x-4">
        <div
          className={classNames(
            isLast ? 'h-6' : '-bottom-6',
            'absolute left-0 top-0 flex w-6 justify-center',
          )}
        >
          <div className="w-px bg-gray-200"/>
        </div>
        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"/>
        </div>
        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
          <span className="block font-medium text-gray-900">
            <Skeleton width={200} height={14} />
            <Skeleton width={300} height={12} />
          </span>
        </p>
        <time className="flex-none py-0.5 text-xs leading-5 text-gray-500">
          <Skeleton width={80} height={20} />
        </time>
      </li>
    )
  }

  return (
    <li className="relative flex gap-x-4">
      <div
        className={classNames(
          isLast ? 'h-6' : '-bottom-6',
          'absolute left-0 top-0 flex w-6 justify-center',
        )}
      >
        <div className="w-px bg-gray-200"/>
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        {logEntry.event === 'User.CheckinMail' ? (
          <EnvelopeIcon aria-hidden="true" className="h-6 w-6 text-primary"/>
        ) : (
          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"/>
        )}
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span className="block font-medium text-gray-900">{logEntry.message}</span>
        {logEntry.additionalMessage}
      </p>
      <time dateTime={logEntry.date} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
        {dateFormat}
      </time>
    </li>
  )
}
