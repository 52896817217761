import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { SlideOverDialogHeader } from "./SlideOverDialogHeader";

type PropTypes = {
  title: string
  open: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const SlideOverDialog = ({open, onClose, title, children}: PropTypes) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
      <div className="fixed inset-0 backdrop-blur-sm" />
      <div className="fixed inset-0 bg-gray-800 opacity-40" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-[calc(100vw-2.5rem)] sm:max-w-[calc(100vw-4rem)] md:max-w-2xl lg:max-w-3xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-2xl">
                  <div className="flex-1">
                    {/* Header */}
                    <SlideOverDialogHeader title={title} onClose={onClose} />

                    {/* Divider container */}
                    {children}
                  </div>

                  {/* Action buttons */}
                  {/*<div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">*/}
                  {/*  <div className="flex justify-end space-x-3">*/}
                  {/*    <button*/}
                  {/*      type="button"*/}
                  {/*      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"*/}
                  {/*      onClick={onClose}*/}
                  {/*    >*/}
                  {/*      Cancel*/}
                  {/*    </button>*/}
                  {/*    <button*/}
                  {/*      type="submit"*/}
                  {/*      className="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"*/}
                  {/*    >*/}
                  {/*      Create*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
)
