// @ts-ignore
import { useSnackbar } from 'notistack';
import React, { createContext, useContext } from 'react';

type SnackbarContextType = {
  pushSuccess: (message: string) => void;
  pushWarning: (message: string) => void;
  pushInfo: (message: string) => void;
  pushError: (message: string) => void;
}

const SnackbarContext = createContext<SnackbarContextType>({
  pushSuccess: (message: string) => {},
  pushWarning: (message: string) => {},
  pushInfo: (message: string) => {},
  pushError: (message: string) => {},
});

export const useSnackbarPush = () => useContext(SnackbarContext)

const SnackbarContextProvider = ({ children }: {children: React.ReactNode}) => {
  const { enqueueSnackbar } = useSnackbar();

  const pushNotification = ({ message, type, style }: {
    message: string
    type: 'success' | 'warning' | 'info' | 'error'
    style: React.CSSProperties
  }) => enqueueSnackbar(message.replace('[GraphQL] ', ''), {variant: type, style, hideIconVariant: true} )

  const pushSuccess = (message: string) => pushNotification({ message, type: 'success', style: { backgroundColor: 'rgb(196, 231, 205)', color: '#000' }})
  const pushWarning = (message: string) => pushNotification({ message, type: 'warning', style: { backgroundColor: 'yellow', color: '#000' }})
  const pushInfo = (message: string) => pushNotification({ message, type: 'info', style: { backgroundColor: 'rgb(220,220,220)', color: '#000' }})
  const pushError = (message: string) => pushNotification({ message, type: 'error', style: { backgroundColor: 'rgb(244, 67, 54)' }})

  return (
    <SnackbarContext.Provider value={{
      pushSuccess,
      pushWarning,
      pushInfo,
      pushError
    }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}

export default SnackbarContextProvider;
