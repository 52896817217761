import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { Fragment, useCallback } from 'react'
import { useMutation } from "urql";
import { updateMultiplePropertyAnswersMutation } from "../../_lib/graphql/mutations";
import { User } from "../../types/graphql/User";
import { EditMembership } from "../EditMembership";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";
import { useMyself } from "../UserContext";

type PropTypes = {
  open: boolean,
  membershipID: string
  title?: string
}

export const MissingMembershipAnswersDialog = ({open, title, membershipID}: PropTypes) => {
  const [, updateMultipleProperties] = useMutation(updateMultiplePropertyAnswersMutation)
  const { pushSuccess, pushError } = useSnackbarPush()
  const profile = useMyself()

  const handleSubmit = useCallback((formData: any) => {
    updateMultipleProperties({
      input: {
        ...Object.values(formData).map((item: any) => ({
          ...item,
          value: typeof item.value === 'object' ? item.value.join(',') : item.value
        }))
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Speichern erfolgreich')
      }
    })
  }, [updateMultipleProperties, pushError, pushSuccess])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                    <PencilSquareIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 pb-4 border-b border-gray-200 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Informationen benötigt
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Es gibt noch unausgefüllte Pflichtfelder in einer deiner Gruppen.<br />
                        Fülle sie direkt aus, um das Tool im Anschluss weiter nutzen zu können.
                      </p>
                    </div>
                  </div>

                  <div className={'pt-4'}>
                    <h3 className={'font-medium text-lg'}>{title}</h3>

                    <EditMembership
                      hideHeader
                      submitOnClick
                      membershipID={membershipID}
                      submitAction={handleSubmit}
                      profile={profile as User}
                      showInfotext
                      showNotificationPermissions
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
