import { useCallback, useMemo } from "react";
import { Property } from "../../../types/graphql/Property";
import { PropertyAnswer } from "../../../types/graphql/PropertyAnswer";

type PropTypes = {
  checkinProperty: Property
  checkinPropertyAnswers?: PropertyAnswer[]
}
export const PropertyAnswerView = ({checkinProperty, checkinPropertyAnswers}: PropTypes) => {

  const displayValue = useCallback((property?: Property, answerValue?: string) => {
    switch (property?.type) {
      case 'dropdown':
      case 'checkboxset':
        return (answerValue || '')
          .split(',')
          .map(v => property?.propertyOptions.find(option => option.id === v)?.title || '')
          .sort()
      case 'checkbox':
        return answerValue === '1' ? ['Ja'] : ['Nein']
      case 'multitext':
        const slipString = answerValue?.split(' ') || []
        return property?.shorten
          ? [`${slipString.slice(0, 15).join(' ')}${slipString.length > 0 && slipString.length < 15 ? '' : '...'}`]
          : [answerValue]
      default:
        return [answerValue]
    }
  }, [])

  const answerValue = useMemo(() => {
    const value = (checkinPropertyAnswers || []).find(item => item.propertyID === checkinProperty.id)?.value || ''

    return displayValue(checkinProperty, value)

  }, [checkinProperty, checkinPropertyAnswers, displayValue])

  return (
    <div className={'grid grid-cols-2 py-1'}>
      <div>
        {checkinProperty.title}
      </div>

      <div>
        {answerValue}
      </div>
    </div>
  )
}
