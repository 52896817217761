import { LockClosedIcon } from "@heroicons/react/24/outline";
import React from "react";
import ReactInputMask from "react-input-mask";
import { classNames } from "../../_helpers";
import parse from 'html-react-parser';

type PropType = {
  label: string;
  value: string;
  className?: string;
  small?: boolean;
  setValue: (value: string) => void;
  isRequired?: boolean;
  vertical?: boolean;
  fullWidthInput?: boolean;
  readOnly?: boolean;
  hasHourMask?: boolean,
  disabled?: boolean,
  isPrivate?: boolean,
  numeric?: boolean,
  mask?: string | any[]
  infotext?: string
}

export const Textfield = ({ disabled, numeric, isPrivate, label, value, setValue, className, small, isRequired, vertical, readOnly, fullWidthInput, hasHourMask, mask = '', infotext }: PropType) => {
  const hourFirst = /[0-2]/;
  const minFirst = /[0-5]/;
  const hourOrMin = /[0-9]/;
  const hourMask = [hourFirst, hourOrMin, ":", minFirst, hourOrMin];

  return (
    <label
      className={classNames(
        'block',
        !vertical ? "sm:grid sm:grid-cols-3 sm:gap-4" : '',
        small ? 'sm:pt-2' : 'sm:pt-5',
        !infotext ? 'items-center' : '',
        className
      )}
    >
      <span
        className={classNames(
          "flex font-medium text-gray-700",
          small ? 'text-xs sm:pt-1' : 'text-sm',
          isRequired ? 'font-bold' : ''
        )}
      >
        {isPrivate && (
          <LockClosedIcon className={'w-4 h-4 mr-2 shrink-0'} />
        )}
        {label}
      </span>
      <span className="block mt-1 sm:col-span-2 sm:mt-0">
        {infotext && (
          <p className={'max-w-lg text-gray-700 text-sm pb-4'}>{parse(infotext)}</p>
        )}
        <ReactInputMask mask={hasHourMask ? hourMask : mask} alwaysShowMask value={value} onChange={e => setValue(e.target.value)} disabled={readOnly || disabled || false}>
          <input
            type={numeric ? 'number': 'text'}
            required={isRequired}
            className={classNames(
              "block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary disabled:text-gray-400",
              !fullWidthInput ? 'max-w-lg sm:max-w-xs' : '',
              small ? 'text-xs' : 'text-sm'
            )}
          />
        </ReactInputMask>
      </span>
    </label>
  )
}
