import React from "react";
import { classNames } from "../../_helpers";

type PropType = {
  label: string | React.ReactNode;
  name?: string;
  description?: string;
  checked: boolean;
  small?: boolean;
  setValue: (checked: boolean) => void;
  isRequired?: boolean,
  bold?: boolean
  disabled?: boolean
  isPrivate?: boolean
  radio?: boolean
}

export const Checkbox = ({ label, name, description, checked, setValue, small, isRequired, bold, disabled, isPrivate, radio }: PropType) => {
  return (
    <label className="relative flex items-start">
      <span className="flex h-5 items-center">
        <input
          // id={id}
          name={name}
          type={radio ? 'radio' : 'checkbox'}
          checked={checked}
          disabled={disabled}
          onChange={e => {
            setValue(e.target.checked)
          }}
          required={isRequired || false}
          className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary disabled:text-gray-300"
        />
      </span>
      <span className="block ml-3 text-sm">
        <span
          // htmlFor={id}
          className={classNames(
            "block font-medium",
            small ? 'text-xs' : 'text-sm',
            isRequired || bold ? 'font-bold' : '',
            disabled ? 'text-gray-400' : 'text-gray-700'
          )}
        >
          {label}
        </span>
        {description && (
          <span className="text-gray-500 whitespace-pre-wrap">
            {description}
          </span>
        )}
      </span>
    </label>
  )
}
