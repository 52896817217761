import React from "react";

type PropTypes = {
  className: string
}

export const MinorAgeIcon = ({ className }: PropTypes) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }}
    className={className}
  >
    <circle cx="9.75" cy="9.75" r="9.75" style={{fill: 'white'}}/>
    <g>
      <path
        d="M7.982,14.75L6.1,14.75L6.1,7.497L3.854,8.194L3.854,6.664L7.78,5.258L7.982,5.258L7.982,14.75Z"
        style={{fillRule: 'nonzero'}}
      />
      <path
        d="M15.906,7.79C15.906,8.25 15.791,8.658 15.561,9.014C15.331,9.37 15.014,9.655 14.611,9.867C15.071,10.089 15.435,10.394 15.704,10.782C15.974,11.17 16.108,11.627 16.108,12.152C16.108,12.994 15.822,13.66 15.249,14.148C14.676,14.636 13.897,14.88 12.912,14.88C11.926,14.88 11.145,14.635 10.568,14.145C9.991,13.654 9.702,12.99 9.702,12.152C9.702,11.627 9.836,11.169 10.106,10.779C10.375,10.388 10.737,10.084 11.193,9.867C10.789,9.655 10.473,9.37 10.245,9.014C10.018,8.658 9.904,8.25 9.904,7.79C9.904,6.983 10.173,6.34 10.711,5.86C11.249,5.38 11.981,5.141 12.905,5.141C13.825,5.141 14.555,5.378 15.096,5.854C15.636,6.329 15.906,6.974 15.906,7.79ZM14.22,12.016C14.22,11.603 14.101,11.273 13.862,11.026C13.623,10.779 13.302,10.655 12.898,10.655C12.499,10.655 12.18,10.778 11.941,11.023C11.703,11.268 11.583,11.599 11.583,12.016C11.583,12.419 11.701,12.745 11.935,12.992C12.169,13.24 12.495,13.363 12.912,13.363C13.319,13.363 13.64,13.244 13.872,13.005C14.104,12.767 14.22,12.437 14.22,12.016ZM14.025,7.882C14.025,7.513 13.927,7.216 13.732,6.993C13.537,6.769 13.261,6.658 12.905,6.658C12.553,6.658 12.28,6.766 12.085,6.983C11.889,7.2 11.792,7.5 11.792,7.882C11.792,8.259 11.889,8.563 12.085,8.793C12.28,9.023 12.556,9.138 12.912,9.138C13.267,9.138 13.542,9.023 13.735,8.793C13.928,8.563 14.025,8.259 14.025,7.882Z"
        style={{fillRule: 'nonzero'}}
      />
    </g>
    <g transform="matrix(0.958837,0,0,0.958837,-1.75599,-1.75599)">
      <path
        d="M18.364,18.364C20.051,16.677 21,14.386 21,12C21,7.063 16.937,3 12,3C9.614,3 7.323,3.949 5.636,5.636M18.364,18.364C16.677,20.051 14.386,21 12,21C7.063,21 3,16.937 3,12C3,9.614 3.949,7.323 5.636,5.636M18.364,18.364L5.636,5.636"
        style={{
          fill: 'white',
          fillOpacity: 0,
          fillRule: 'nonzero',
          stroke: 'rgb(224,36,36)',
          strokeWidth: '1.7px',
        }}
      />
    </g>
  </svg>
)
