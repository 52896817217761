import { Checkbox, Fieldgroup, Selectfield, Textfield } from "../../_ui/forms";
import { Textareafield } from "../../_ui/forms/Textareafield";
import { Property } from "../../types/graphql/Property";
import parse from 'html-react-parser';

type PropTypes = {
  property: Property
  answer?: string | string[]
  small?: boolean
  submitOnClick?: boolean
  readonly?: boolean
  showInfotext?: boolean
  changeAnswer: (answer: string | string[]) => void
}

export const EditMembershipFieldView = ({ readonly, property, answer, changeAnswer, small, submitOnClick, showInfotext }: PropTypes) => {
  switch (property.type) {
    case 'optionset': return (
      <Fieldgroup
        small={small}
        label={`${property.title || ''} ${property.isRequired ? '*' : ''}`}
        isRequired={property.isRequired}
        isPrivate={property.isPrivate}
      >
        {showInfotext && property.infotext && (
          <p className={'text-gray-700 text-sm'}>{parse(property.infotext)}</p>
        )}
        {property.propertyOptions.map(propertyOption => (
          <>
            <Checkbox
              radio
              name={property.id}
              key={propertyOption.id}
              small={small}
              label={propertyOption.title}
              description={propertyOption.description}
              disabled={readonly || propertyOption.disabled}
              isPrivate={property.isPrivate}
              checked={answer === propertyOption.id}
              setValue={() => changeAnswer(propertyOption.id)}
            />
          </>
        ))}
      </Fieldgroup>
    )
    case 'dropdown': return (
      <Selectfield
        small={small}
        label={`${property.title || ''} ${property.isRequired ? '*' : ''}`}
        isRequired={property.isRequired}
        value={answer?.toString() || ''}
        setValue={value => changeAnswer(value)}
        disabled={readonly}
        isPrivate={property.isPrivate}
        infotext={showInfotext ? property.infotext : undefined}
      >
        {property.propertyOptions.map(propertyOption => (
          <option key={propertyOption.id} value={propertyOption.id}>
            {propertyOption.title}
          </option>
        ))}
      </Selectfield>
    )
    case 'checkbox': return (
      <Fieldgroup
        small={small}
        label={`${property.title || ''} ${property.isRequired ? '*' : ''}`}
        isPrivate={property.isPrivate}
      >
        {showInfotext && property.infotext && (
          <p className={'text-gray-700 text-sm'}>{parse(property.infotext)}</p>
        )}
        <Checkbox
          small={small}
          label={property.title}
          checked={answer?.toString() === '1' || false}
          setValue={checked => changeAnswer(checked ? '1' : '0')}
          isRequired={property.isRequired}
          disabled={readonly}
          isPrivate={property.isPrivate}
        />
      </Fieldgroup>
    )
    case 'checkboxset': return (
      <Fieldgroup
        small={small}
        label={`${property.title || ''} ${property.isRequired ? '*' : ''}`}
        isRequired={property.isRequired}
        isPrivate={property.isPrivate}
      >
        {showInfotext && property.infotext && (
          <p className={'text-gray-700 text-sm'}>{parse(property.infotext)}</p>
        )}
        {property.propertyOptions.map(propertyOption => (
          <Checkbox
            key={propertyOption.id}
            small={small}
            label={propertyOption.title}
            description={propertyOption.description}
            disabled={readonly || propertyOption.disabled}
            isPrivate={property.isPrivate}
            checked={answer && typeof answer !== 'string' ? answer.includes(propertyOption.id) : false}
            setValue={checked => {
              if (typeof answer === 'string') {
                return []
              }

              answer?.includes(propertyOption.id)
                ? changeAnswer(answer?.filter(id => propertyOption.id !== id))
                : changeAnswer([...(answer || []), propertyOption.id].filter((v, i, a) => a.indexOf(v) === i))
            }}
          />
        ))}
      </Fieldgroup>
    )
    case 'multitext': return (
      <Textareafield
        small={small}
        label={`${property.title || ''} ${property.isRequired ? '*' : ''}`}
        isRequired={property.isRequired}
        value={answer?.toString() || ''}
        setValue={value => changeAnswer(value)}
        disabled={readonly}
        isPrivate={property.isPrivate}
        infotext={showInfotext ? property.infotext : undefined}
      />
    )
    default: return (
      <Textfield
        small={small}
        label={`${property.title || ''} ${property.isRequired ? '*' : ''}`}
        value={answer?.toString() || ''}
        isRequired={property.isRequired}
        setValue={value => changeAnswer(value)}
        disabled={readonly}
        isPrivate={property.isPrivate}
        infotext={showInfotext ? property.infotext : undefined}
      />
    )
  }
}
