import React from "react";
import { classNames } from "../_helpers";

type PropTypes = {
  children: React.ReactNode
  type?: 'button' | 'submit' | 'reset'
  size?: 'small' | 'large' | 'default'
  onClick?: () => void
  disabled?: boolean
  fullWidth?: boolean
  autoFocus?: boolean
  color?: 'primary' | 'success' | 'error' | 'default'
  icon?: React.ReactNode
}

export const Button = ({children, type, disabled, onClick, fullWidth, autoFocus = false, color = 'default', size = 'default', icon}: PropTypes) => (
  <button
    autoFocus={autoFocus}
    onClick={onClick}
    className={classNames(
      'inline-flex items-center justify-center rounded-md border border-transparent text-sm font-medium shadow-sm focus:outline-none gap-2',
      color === 'primary' ? 'bg-primary text-white hover:bg-primary-300' : '',
      color === 'success' ? 'bg-lime-300 text-black ring-inset' : '',
      color === 'error' ? 'bg-red-500 text-white ring-inset' : '',
      color === 'default' ? 'bg-gray-200 text-gray-900 ring-inset' : '',
      size === 'default' ? 'px-4 py-2 text-sm font-medium' : '',
      size === 'small' ? 'px-2 py-1.5 text-xs' : '',
      size === 'large' ? 'px-6 py-3 text-lg' : '',
      'disabled:bg-gray-300 disabled:cursor-not-allowed',

      fullWidth ? 'w-full' : null
    )}
    type={type}
    disabled={disabled}
    // className={'w-full border border-primary rounded text-white block mt-2 px-4 py-1 text-sm text-center hover:bg-primary hover:text-white'}
  >
    {icon}
    {children}
  </button>
)
