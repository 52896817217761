import React from "react";
import { Button } from "./Button";

type PropTypes = {
  title: string
  subtitle?: string | React.ReactNode
  action?: () => void
  actionButton?: boolean
  actionContent?: React.ReactNode
  actionTitle?: string | React.ReactNode
  secondaryAction?: () => void
  secondaryActionButton?: boolean
  secondaryActionContent?: React.ReactNode
  secondaryActionTitle?: string | React.ReactNode
}

export const MainHeader = ({title, subtitle, actionTitle, action, actionContent, actionButton, secondaryAction, secondaryActionTitle, secondaryActionContent, secondaryActionButton}: PropTypes) => (
  <div className="flex sm:items-center flex-wrap sm:flex-nowrap gap-x-4 lg:flex-wrap xl:flex-nowrap">
    <div className="sm:flex-auto">
      <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
      {typeof subtitle === 'string' ? (
        <p className="mt-2 text-sm text-gray-700">{subtitle}</p>
      ) : (
        <div className={'mt-2 prose max-w-full text-gray-700 text-sm'}>
          {subtitle}
        </div>
      )}
    </div>
    {(secondaryAction || secondaryActionContent) && secondaryActionTitle && (
      <div className="mt-4 sm:mt-0 sm:flex-none self-start">
        {secondaryActionButton && secondaryAction ? (
          <Button onClick={secondaryAction}>
            {secondaryActionTitle}
          </Button>
        ) : secondaryActionContent}
      </div>
    )}
    {(action || actionContent) && actionTitle && (
      <div className="mt-4 sm:mt-0 sm:flex-none self-start">
        {actionButton && action ? (
          <Button color={"primary"} onClick={action}>
            {actionTitle}
          </Button>
        ) : actionContent}
      </div>
    )}
  </div>
)
