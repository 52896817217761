import React from "react";
import { classNames } from "../_helpers";

type PropTypes = {
  children?: React.ReactNode;
  color: string
  small?: boolean
}

export const Badge = ({ children, color, small }: PropTypes) => {
  if (color === 'lime') {
    return (
      <span className={classNames(
        'inline-flex place-self-center rounded-full py-0.5 text-xs font-medium text-lime-800',
        small ? 'aspect-square w-[9px] md:w-[12px] bg-lime-300' : 'bg-lime-200 px-2.5'
      )}>
        {children}
      </span>
    )
  }

  if (color === 'red') {
    return (
      <span className={classNames(
        'inline-flex place-self-center rounded-full py-0.5 text-xs font-medium text-red-800',
        small ? 'aspect-square w-[9px] md:w-[12px] bg-red-300' : 'bg-red-200 px-2.5'
      )}>
        {children}
      </span>
    )
  }

  if (color === 'yellow') {
    return (
      <span className={classNames(
        'inline-flex place-self-center rounded-full py-0.5 text-xs font-medium text-yellow-800',
        small ? 'aspect-square w-[9px] md:w-[12px] bg-yellow-300' : 'bg-yellow-200 px-2.5'
      )}>
        {children}
      </span>
    )
  }

  if (color === 'green') {
    return (
      <span className={classNames(
        'inline-flex place-self-center rounded-full py-0.5 text-xs font-medium text-green-800',
        small ? 'aspect-square w-[9px] md:w-[12px] bg-green-300' : 'bg-green-200 px-2.5'
      )}>
        {children}
      </span>
    )
  }

  if (color === 'blue') {
    return (
      <span className={classNames(
        'inline-flex place-self-center rounded-full py-0.5 text-xs font-medium text-blue-800',
        small ? 'aspect-square w-[9px] md:w-[12px] bg-blue-300' : 'bg-blue-200 px-2.5'
      )}>
        {children}
      </span>
    )
  }

  if (color === 'teal') {
    return (
      <span className={classNames(
        'inline-flex place-self-center rounded-full py-0.5 text-xs font-medium text-teal-800',
        small ? 'aspect-square w-[9px] md:w-[12px] bg-teal-300' : 'bg-teal-200 px-2.5'
      )}>
        {children}
      </span>
    )
  }

  if (color === 'orange') {
    return (
      <span className={classNames(
        'inline-flex place-self-center rounded-full py-0.5 text-xs font-medium text-orange-800',
        small ? 'aspect-square w-[9px] md:w-[12px] bg-orange-300' : 'bg-orange-200 px-2.5'
      )}>
        {children || null}
      </span>
    )
  }

  if (color === 'purple') {
    return (
      <span className={classNames(
        'inline-flex place-self-center rounded-full py-0.5 text-xs font-medium text-purple-800',
        small ? 'aspect-square w-[9px] md:w-[12px] bg-purple-300' : 'bg-purple-200 px-2.5'
      )}>
        {children}
      </span>
    )
  }

  return <>{color}</>
}
