import React from "react";

type PropTypes = {
  children: React.ReactNode;
}

export const MainContainer = ({children}: PropTypes) => (
  <div className={'mt-8 mb-20 px-4 pb-8 sm:px-6 lg:px-8'}>
    {children}
  </div>
)
