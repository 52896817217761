import React from "react";
import { Badge } from "../../_ui/Badge";
import { Dialog } from "../../_ui/Dialog";
import { StatusLabel } from "../../types/graphql/StatusLabel";

type PropTypes = {
  open: boolean,
  onClose: () => void,
  statusLabels: StatusLabel[],
  availableStatus: StatusLabel[],
  handleSubmit: (statusLabelID: string, checked: boolean) => void
  fetching: boolean
}

export const AddMembershipDialog = ({ open, onClose, availableStatus, statusLabels, handleSubmit, fetching }: PropTypes) => {
  return (
    <Dialog title={'Status-Label bearbeiten'} open={open} onClose={onClose}>
      {availableStatus.map(status => (
        <div key={status.id} className={'grid grid-cols-[40px,1fr] items-center'}>
          <input
            id={`status-label-option-${status.id}`}
            name={status.id}
            type="checkbox"
            checked={statusLabels.findIndex(label => label.id === status.id) >= 0}
            onChange={e => handleSubmit(status.id, e.target.checked)}
            className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-0"
          />
          <label htmlFor={`status-label-option-${status.id}`}>
            <Badge color={status.color}>
              {status.title}
            </Badge>
          </label>
        </div>
      ))}
    </Dialog>
  )
}
