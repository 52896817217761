import React, { useCallback, useMemo } from "react";
import { useMutation } from "urql";
import { addSectionRoleToCurrentSeasonMutation } from "../../../_lib/graphql/mutations";
import { Checkbox } from "../../../_ui/forms";
import { SectionRole as SectionRoleType } from "../../../types/graphql/SectionRole";
import { User } from "../../../types/graphql/User";
import { useSnackbarPush } from "../../SnackbarContext/SnackbarContext";

type PropTypes = {
  sectionRole: SectionRoleType
  profile: User
  disabled: boolean
}

export const SectionRole = ({sectionRole, profile, disabled}: PropTypes) => {
  const { pushSuccess, pushError } = useSnackbarPush()
  const [, addSectionRoleToCurrentSeason] = useMutation(addSectionRoleToCurrentSeasonMutation)

  const handleChange = useCallback((checked: boolean) => {
    addSectionRoleToCurrentSeason({
      input: {
        userID: profile.id,
        sectionRoleID: sectionRole.id,
        remove: !checked
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Speichern erfolgreich')
      }
    })
  }, [profile, sectionRole, addSectionRoleToCurrentSeason, pushSuccess, pushError])


  const checked = useMemo(() => {
    return (profile.sectionRoles || []).findIndex(role => role.id === sectionRole.id) >= 0
  }, [profile, sectionRole])

  return (
    <Checkbox
      key={sectionRole.id}
      label={sectionRole.title}
      checked={checked}
      disabled={disabled}
      setValue={handleChange}
    />
  )
}
