import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';
import { getDatesInRange } from "../../_helpers";
import { readCurrentSeasonQuery } from "../../_lib/graphql/queries";
import { LineupItem } from "../../types/graphql/LineupItem";
import { Season } from "../../types/graphql/Season";

const ScheduleContext = createContext<{
  title: string
  dates: null | Date[],
  festivalDates: null | Date[],
  scheduleDate: null | Date,
  lineupItems: LineupItem[],
  season: Season,
  seasonFetching: boolean,
  setScheduleDate: (date: Date) => void
} | null>(null);

export const useSchedule = () => useContext(ScheduleContext)

type PropTypes = {
  children: React.ReactNode
}

const ScheduleContextProvider = ({ children }: PropTypes) => {
  const [scheduleDate, setScheduleDate] = useState<null | Date>(null)

  const [currentSeasonResult] = useQuery({
    query: readCurrentSeasonQuery,
  })

  const dates = useMemo(() => {
    const currentSeason = currentSeasonResult.data?.readCurrentSeason

    if (currentSeason) {
      return getDatesInRange(new Date(currentSeason.constructionBegin), new Date(currentSeason.dismantlingEnd))
    }

    return null;
  }, [currentSeasonResult.data])

  const festivalDates = useMemo(() => {
    const currentSeason = currentSeasonResult.data?.readCurrentSeason

    if (currentSeason) {
      return getDatesInRange(new Date(currentSeason.begin), new Date(currentSeason.end))
    }

    return null;
  }, [currentSeasonResult.data])

  useEffect(() => {
    if (!scheduleDate && dates && dates.length > 0) {
      const today = new Date()
      if (dates.find(date => date === today)) {
        setScheduleDate(today)
      } else {
        setScheduleDate(dates[0])
      }
    }
  }, [dates, scheduleDate, setScheduleDate])

  return (
    <ScheduleContext.Provider
      value={{
        dates,
        festivalDates,
        scheduleDate,
        setScheduleDate,
        season: currentSeasonResult.data?.readCurrentSeason,
        seasonFetching: currentSeasonResult.fetching,
        lineupItems: currentSeasonResult.data?.readCurrentSeason?.lineupItems || [],
        title: currentSeasonResult.data?.readCurrentSeason?.title || ''
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
}

export default ScheduleContextProvider;
