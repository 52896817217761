import { useQuery } from "urql";
import { readUserLogEntriesQuery } from "../../_lib/graphql/queries";
import { Dialog } from "../../_ui/Dialog";
import { UserLogsView } from "./UserLogsView";

type PropTypes = {
  userID: string
  open: boolean
  onClose: () => void
}

export const UserLogs = ({ userID, open, onClose }: PropTypes) => {
  const [userLogsResult] = useQuery({
    query: readUserLogEntriesQuery,
    variables: {
      input: {
        userID
      }
    },
    requestPolicy: 'network-only',
    pause: !userID || !open
  })

  return (
    <Dialog open={open} onClose={onClose} title={'Änderungshistorie'}>
      <UserLogsView
        entries={userLogsResult.data?.readOneUser?.logEntries || new Array(6)}
        fetching={userLogsResult.fetching || userLogsResult.stale}
      />
    </Dialog>
  )
}
