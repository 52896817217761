import React from "react";

type PropTypes = {
  children: React.ReactNode;
}

export const List = ({children}: PropTypes) => (
  <div className="mt-6 flow-root">
    <ul className="-my-5 divide-y divide-gray-200">
      {children}
    </ul>
  </div>
)
