import { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { useQuery } from "urql";
import { readUserAvailabilitiesQuery } from "../../_lib/graphql/queries";
import { AvailabilitySlot } from "../../types/graphql/AvailabilitySlot";
import { EditAvailabilityView } from "./EditAvailabilityView";
import { EditForm } from "./EditForm";

type PropTypes = {
  userID?: string
  readonly: boolean
}

export const EditAvailabilities = ({ userID, readonly }: PropTypes) => {
  const [availabilityResult] = useQuery({
    query: readUserAvailabilitiesQuery,
    variables: {
      input: {
        userID: userID
      }
    },
    pause: !userID
  })

  const groupedAvailabilitySlots = useMemo(() => {
    const availabilitySlots = availabilityResult.data?.readOneUser?.availabilitySlots || []
    return availabilitySlots?.reduce((acc: {[date: string]: AvailabilitySlot[]}, slot: AvailabilitySlot) => {
      const date = slot.begin.slice(0, 10)

      return {
        ...acc,
        [date]: [
          ...(acc[date] || []),
          slot
        ]
      }
    }, {}) || {}
  }, [availabilityResult.data?.readOneUser?.availabilitySlots])

  if (readonly) {
    return (
      <div className={'grid gap-4'}>
        {Object.keys(groupedAvailabilitySlots).map(date => (
          <div key={date}>
            <div className={'font-bold md:text-sm pb-2'}>
              {new Date(date).toLocaleDateString('de-DE', {
                weekday: 'short',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </div>
            <div className={'grid gap-3'}>
              {groupedAvailabilitySlots[date].map((slot: AvailabilitySlot) => (
                <EditAvailabilityView key={slot.id} slot={slot} readonly={readonly} />
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Routes>
      <Route path={':availabilityID'} element={<EditForm />} />
      <Route index element={
        <div className={'grid gap-4'}>
          {Object.keys(groupedAvailabilitySlots).map(date => (
            <div key={date}>
              <div className={'font-bold md:text-sm pb-2'}>
                {new Date(date).toLocaleDateString('de-DE', {
                  weekday: 'short',
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </div>
              <div className={'grid gap-3'}>
                {groupedAvailabilitySlots[date].map((slot: AvailabilitySlot) => (
                  <EditAvailabilityView key={slot.id} slot={slot} readonly={readonly} />
                ))}
              </div>
            </div>
          ))}
        </div>
      } />
    </Routes>
  )
}
