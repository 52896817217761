import { CheckIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { classNames } from "../../../_helpers";
import { Season as SeasonType } from "../../../types/graphql/Season";

type PropTypes = {
  season: SeasonType
  currentSeason?: SeasonType
  onClose: () => void
}

export const Season = ({ season, currentSeason }: PropTypes) => {
  const handleSwitch = useCallback(() => {
    if (!season.isLatest) {
      window.sessionStorage.setItem('season', season.id)
    } else {
      window.sessionStorage.removeItem('season')
    }

    window.location.reload()
  }, [season.id, season.isLatest])

  return (
    <div
      onClick={handleSwitch}
      className={classNames(
        'relative flex items-center justify-between space-y-1 rounded-lg   px-6 py-5 shadow-sm cursor-pointer focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2',
        season.id === currentSeason?.id ? 'border border-gray-500 hover:border-gray-400 bg-gray-300/80' : 'border border-gray-300 hover:border-gray-400 bg-gray-200/50'
      )}
    >
      <div className={'flex flex-col gap-1 leading-none'}>
        <div>
          {season.title}
        </div>

        {season.begin && (
          <div className={'text-xs'}>
            {new Date(season.begin).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}

            {season.begin !== season.end && (
              <>
                &nbsp;-&nbsp;
                {new Date(season.end).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}
              </>
            )}
          </div>
        )}
      </div>
      <div>
        <div className={classNames(
          'flex items-center justify-center rounded-full w-6 h-6 border',
          season.id === currentSeason?.id && 'border-gray-500 bg-gray-500',
          season.id !== currentSeason?.id && 'border-gray-300'
        )}>
          {season.id === currentSeason?.id && (
            <CheckIcon className={'w-5 h-5 text-white'} />
          )}
        </div>
      </div>
    </div>
  )
}
