import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { APP_EDIT_MEMBERSHIPS } from "../../../_constants/permissions";
import { User } from "../../../types/graphql/User";
import { EditMembership } from "../../EditMembership";
import { StatusLabel } from "../../StatusLabel";
import { useMyself } from "../../UserContext";
import { MembershipStatus } from "../MembershipStatus";
import { MembershipTabs } from "../MembershipTabs";

type PropTypes = {
  loading: boolean
  profile: User
  updateMembershipStatus: (membershipID: string, status: string) => void
}

export const Memberships = ({ loading, profile, updateMembershipStatus }: PropTypes) => {
  const { canAccess } = useMyself() || {}
  const [searchParams] = useSearchParams();

  const groupID = useMemo(() => {
    if (searchParams) {
      return searchParams.get('group')
    }

    return null
  }, [searchParams])

  const currentMembership = useMemo(() => {
    return profile?.memberships?.find(membership => membership?.userGroup?.id === groupID)
  }, [profile, groupID])

  const currentMembershipStatus = useMemo(() => {
    return profile?.memberships?.find(membership => membership?.userGroup?.id === groupID)?.status
  }, [profile, groupID])

  const currentMembershipStatusTitle = useMemo(() => {
    switch (currentMembershipStatus) {
      case 'active': return 'Bestätigt'
      case 'requested': return 'Angefragt'
      default: return ''
    }

  }, [currentMembershipStatus])

  return (
    <>
      <h3 className={'font-medium pb-4'}>Gruppen</h3>

      {(!loading || profile?.memberships) && (
        <div className={'mb-4'}>
          <MembershipTabs memberships={profile?.memberships} />
        </div>
      )}

      {currentMembership && (
        <div className={'bg-primary-500 rounded-xl p-4 mt-2 mb-4 sm:mb-0 text-sm border-t-2 border-white'}>
          <h5 className={'text-lg pb-1'}>{currentMembership?.userGroup?.title || ''}</h5>
          <div className={'grid grid-cols-[1fr,1fr,1fr,45px] gap-2 items-center'}>
            <div className={'py-1 text-gray-700'}>Mitgliedschaft:</div>
            <div className={'col-span-3'}>
              <MembershipStatus
                currentMembership={currentMembership}
                currentMembershipStatus={currentMembershipStatus || ''}
                currentMembershipStatusTitle={currentMembershipStatusTitle}
                updateMembershipStatus={updateMembershipStatus}
              />
            </div>

            <StatusLabel
              membership={currentMembership}
              userGroup={currentMembership?.userGroup}
            />
          </div>
        </div>
      )}

      {groupID && (!loading || profile?.memberships) && (
        <>
          <EditMembership
            readonly={!canAccess || !canAccess(APP_EDIT_MEMBERSHIPS)}
            membershipID={profile.memberships?.find(membership => membership?.userGroup?.id === groupID)?.id}
            hideHeader
            includeStatus
          />
        </>
      )}
    </>
  )
}
