import { PlusIcon } from "@heroicons/react/20/solid";
import React from "react";

type PropTypes = {
  title: string;
  addAction?: () => void;
}

export const ListHeadline = ({title, addAction}: PropTypes) => (
  <div className="mt-6  flow-root ">
    <div className={'border-b border-gray-300 px-4'}>
      <div className={'flex items-center justify-between text-xl font-semibold text-gray-900 pb-4'}>
        {title}
        {addAction && (
          <button className={'py-2'} onClick={addAction}>
            <PlusIcon className={'w-5 h-5'} />
          </button>
        )}
      </div>
    </div>
  </div>
)
