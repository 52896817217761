import React, { Fragment, useMemo } from "react";
import { Season } from "../../../types/graphql/Season";
import { Section } from "../../../types/graphql/Section";
import { SectionRole } from "../../../types/graphql/SectionRole";
import { User } from "../../../types/graphql/User";
import { SectionEdit } from "../../SectionEdit";
import { SectionLeadEdit } from "../../SectionLeadEdit";

type PropTypes = {
  profile: User
  seasons: Season[]
}

export const LeadsAndSections = ({profile, seasons}: PropTypes) => {

  const sectionEditComponent = useMemo(() => (
    <SectionEdit
      profile={profile}
    />
  ), [profile])

  const sectionLeadEditComponent = useMemo(() => (
    <SectionLeadEdit
      profile={profile}
    />
  ), [profile])

  const groupedSectionRoles = useMemo(() => {
    return (profile?.sectionRoles || []).reduce((acc: any, role) => ({
      ...acc,
      [role.season]: {
        ...acc[role.season],
        title: seasons.find(season => season.id === role.season)?.title || '--',
        roles: [
          ...(acc[role.season]?.roles || []),
          role
        ]
      }
    }), seasons?.reduce((acc: any, item: Season) => ({...(acc || {}), [item.id]: { title: item.title, roles: [] }}), {}) || {})
  }, [profile, seasons])

  const groupedSections = useMemo(() => {
    return (profile?.sections || []).reduce((acc: any, section) => ({
      ...acc,
      [section.season]: {
        ...acc[section.season],
        title: seasons.find(season => season.id === section.season)?.title || '--',
        sections: [
          ...(acc[section.season]?.sections || []),
          section
        ]
      }
    }), seasons?.reduce((acc: any, item: Season) => ({...(acc || {}), [item.id]: { title: item.title, sections: [] }}), {}) || {})
  }, [profile, seasons])

  const groupedSectionLeads = useMemo(() => {
    return (profile?.sectionLeads || []).reduce((acc: any, section) => ({
      ...acc,
      [section.season]: {
        ...acc[section.season],
        title: seasons.find(season => season.id === section.season)?.title || '--',
        sections: [
          ...(acc[section.season]?.sections || []),
          section
        ]
      }
    }), seasons?.reduce((acc: any, item: Season) => ({...(acc || {}), [item.id]: { title: item.title, sections: [] }}), {}) || {})
  }, [profile, seasons])

  return (
    <div className={'grid sm:grid-cols-3 gap-2'}>
      <div>
        <div className={'flex gap-2 items-center pb-4'}>
          <h3 className={'font-bold text-[14px]'}>Leitung</h3>
          {sectionLeadEditComponent}
        </div>

        {Object.keys(groupedSectionLeads)
          .sort((a, b) => groupedSectionLeads[a].title.localeCompare(groupedSectionLeads[b].title) * -1)
          .map(title => (
          <Fragment key={title}>
            <h6 className={'text-[13px] pb-1 italic font-medium'}>{groupedSectionLeads[title].title}</h6>
            <ul className={'mb-2'}>
              {groupedSectionLeads[title].sections.length === 0 && (
                <li className={'text-xs font-light'}>
                  &mdash;
                </li>
              )}
              {groupedSectionLeads[title].sections.map((section: Section) => (
                <li
                  key={section.id}
                  className={'text-xs font-light'}
                >
                  {section.title}
                </li>
              ))}
            </ul>
          </Fragment>
        ))}
      </div>
      <div>
        <div className={'flex gap-2 items-center pb-4'}>
          <h3 className={'font-bold text-[14px]'}>Bereiche</h3>
          {sectionEditComponent}
        </div>

        {Object.keys(groupedSections)
          .sort((a, b) => groupedSections[a].title.localeCompare(groupedSections[b].title) * -1)
          .map(title => (
          <Fragment key={title}>
            <h6 className={'text-[13px] pb-1 italic font-medium'}>{groupedSections[title].title}</h6>
            <ul className={'mb-2'}>
              {groupedSections[title].sections.length === 0 && (
                <li className={'text-xs font-light'}>
                  &mdash;
                </li>
              )}
              {groupedSections[title].sections.map((section: Section) => (
                <li
                  key={section.id}
                  className={'text-xs font-light'}
                >
                  {section.title}
                </li>
              ))}
            </ul>
          </Fragment>
        ))}
      </div>
      <div>
        <div className={'flex gap-2 items-center pb-4'}>
          <h3 className={'font-bold text-[14px]'}>Rollen</h3>
          {sectionEditComponent}
        </div>

        {Object.keys(groupedSectionRoles)
          .sort((a, b) => groupedSectionRoles[a].title.localeCompare(groupedSectionRoles[b].title) * -1)
          .map(title => (
          <Fragment key={title}>
            <h6 className={'text-[13px] pb-1 italic font-medium'}>{groupedSectionRoles[title].title}</h6>
            <ul className={'mb-2'}>
              {groupedSectionRoles[title].roles.length === 0 && (
                <li className={'text-xs font-light'}>
                  &mdash;
                </li>
              )}
              {groupedSectionRoles[title].roles.map((sectionRole: SectionRole) => (
                <li
                  key={sectionRole.id}
                  className={'text-xs font-light'}
                >
                  {sectionRole.title}
                </li>
              ))}
            </ul>
          </Fragment>
        ))}

        {/*{profile?.sectionRoles && profile?.sectionRoles?.length > 0 && (*/}
        {/*  <h6 className={'text-xs pb-2 italic'}>RaB 2022</h6>*/}
        {/*)}*/}
        {/*<ul>*/}
        {/*  {profile?.sectionRoles?.map(sectionRole => (*/}
        {/*    <li*/}
        {/*      key={sectionRole.id}*/}
        {/*      className={'text-sm'}*/}
        {/*    >*/}
        {/*      {sectionRole.title}*/}
        {/*    </li>*/}
        {/*  ))}*/}
        {/*</ul>*/}
      </div>
    </div>
  )
}
