import { Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, UserIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "urql";
import { classNames } from "../../_helpers";
import { readUserSearchQuery } from "../../_lib/graphql/queries";
import { User } from "../../types/graphql/User";
import { SearchInput } from "./SearchInput";


export const Searchbar = () => {
  const [query, setQuery] = useState('')
  const [resultsOpen, setResultsOpen] = useState(false)
  const  [, setSearchParams] = useSearchParams()

  const timeoutRef = useRef(0)
  const searchTextRef = useRef('')

  const [searchResult, executeSearchQuery] = useQuery({
    query: readUserSearchQuery,
    variables: {
      input: {
        query
      }
    },
    pause: true
  })

  useEffect(() => {
    searchTextRef.current = query
    if (query) {
      setTimeout(() => {
        if (searchTextRef.current === query) {
          executeSearchQuery()
          timeoutRef.current = 700
        }
      }, timeoutRef.current)
    }
  }, [query, executeSearchQuery])

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setResultsOpen(false)
      }
    }
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keyup', onKeyDown);
  }, []);

  return (
    <>
      <div className="flex w-full md:ml-0 z-30">
        <div
          className={classNames('relative w-full text-gray-400 focus-within:text-gray-600')}
          onClick={() => setResultsOpen && setResultsOpen(true)}
        >
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center cursor-pointer">
            <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
          </div>
          <div
            id="search-field-button"
            className={classNames(
              'flex items-center h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-300 cursor-pointer',
              'hidden md:flex'
            )}
          >
            <span>Suche nach Vornamen, Nachnamen, Email</span>
          </div>
        </div>
      </div>

      <Transition show={resultsOpen} as={Fragment}>
        <Dialog open={resultsOpen} onClose={() => setResultsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={'fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity backdrop-blur-sm z-10'} />
          </Transition.Child>

          <div className="fixed inset-0 flex items-start justify-center z-10">
            {/*<Dialog.Panel className={'bg-white max-w-xl mt-[32px] mx-auto max-h-[calc(100vh-63px-24px-36px)] rounded-lg ring-[18px] p-2 ring-white overflow-scroll'}>*/}
            <Dialog.Panel>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={'fixed top-0 left-0 flex items-center w-screen h-16 bg-white md:pl-28'}>
                  <div className={'flex flex-1 justify-between px-4 sm:px-6'}>
                    <SearchInput isInput query={query} setQuery={setQuery} resultsOpen={resultsOpen} setResultsOpen={setResultsOpen} fetching={searchResult.fetching} />
                  </div>
                </div>
              </Transition.Child>

              <div className={'w-[calc(100vw-28px)] max-w-xl bg-white p-6 md:p-8 rounded-xl mt-[80px] shadow-xl'}>
                <div className={'max-h-[calc(100vh-200px)] overflow-scroll'}>
                  <h1 className={'text-xl font-bold pb-4'}>Suchergebnisse</h1>

                  {(!searchResult.data || searchResult.data?.readUserSearch?.length === 0) && (
                    <div className={'text-gray-600'}>
                      Keine Suchergebnisse gefunden.
                    </div>
                  )}

                  <div className={'flex flex-col divide-y'}>
                    {searchResult.data?.readUserSearch?.map((user: User) => (
                      <div key={user.id} className={'grid grid-cols-[40px,1fr] py-2 items-center gap-2 cursor-pointer'} onClick={() => {
                        setResultsOpen(false)
                        setSearchParams(prev => {
                          prev.set('modal', `user-${user.id}`)
                          return prev
                        })
                      }}>
                        <div>
                        {!user.profileImageUrl ? (
                          <>
                            <span
                              className="flex h-8 w-8 rounded-full bg-gray-200 text-gray-400"
                            >
                              <span className={'w-6 h-6 m-auto leading-relaxed'}>
                                <UserIcon className={'w-6 h-6'} />
                              </span>
                            </span>
                          </>
                        ) : (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.profileImageUrl}
                            alt=""
                          />
                        )}
                        </div>
                        <div>
                          {user.firstName} {user.surname}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
