import { PencilIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { APP_EDIT_SECTION_LEAD } from "../../_constants/permissions";
import { User } from "../../types/graphql/User";
import { useSchedule } from "../ScheduleContext";
import { useMyself } from "../UserContext";
import { SectionLeadEditView } from "./SectionLeadEditView";

type PropTypes = {
  profile: User
}

export const SectionLeadEdit = ({profile}: PropTypes) => {
  const [open, setOpen] = useState(false)
  const { canAccess } = useMyself() || {}
  const { season } = useSchedule() || {}

  if (canAccess && !canAccess(APP_EDIT_SECTION_LEAD)) {
    return null
  }

  return (
    <>
      <button
        className={'flex justify-center disabled:text-gray-400 focus:outline-0'}
        onClick={() => setOpen(true)}
      >
        <PencilIcon className={'w-4 h-4'} />
      </button>

      <SectionLeadEditView
        open={open}
        onClose={() => setOpen(false)}
        season={season}
        profile={profile}
      />
    </>
  )
}
