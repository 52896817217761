import { ClockIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useQuery } from "urql";
import { APP_CONTROLLISTS } from "../_constants/permissions";
import { NotAllowed } from "../_features/NotAllowed";
import { useMyself } from "../_features/UserContext";
import { UserList } from "../_features/UserList";
import { SingleColumn } from "../_layout/content";
import { readCurrentSeasonControlListQuery } from "../_lib/graphql/queries";
import { LandingHeader } from "../_ui/LandingHeader";

type PropTypes = {
  pageLink: string
}

export const ControllistPage = ({ pageLink }: PropTypes) => {
  const { canAccess } = useMyself() || {}
  const [readCurrentSeasonControlListResult] = useQuery({
    query: readCurrentSeasonControlListQuery,
    pause: !canAccess || !canAccess([APP_CONTROLLISTS])
  })

  if (!canAccess || !canAccess([APP_CONTROLLISTS])) {
    return <NotAllowed />
  }

  return (
    <SingleColumn>
      <LandingHeader
        title={'Kontrolllisten'}
        icon={ClockIcon}
      />

      <div className={'w-full max-w-[1400px] mx-auto p-8'}>
        <h3 className={'text-xl font-medium tracking-tight text-gray-900 sm:text-2xl mb-4'}>Benutzer mit Verfügbarkeiten,
          die nicht in der Festivalgruppe sind</h3>
        <UserList
          user={readCurrentSeasonControlListResult.data?.readCurrentSeason?.userWithAvailabilitiesWithoutTargetGroup || []}
          fetching={readCurrentSeasonControlListResult.fetching}
        />
      </div>

      <div className={'w-full max-w-[1400px] mx-auto p-8'}>
        <h3 className={'text-xl font-medium tracking-tight text-gray-900 sm:text-2xl mb-4'}>
          Benutzer mit Bereichszuordnung, die nicht in der Festivalgruppe sind
        </h3>
        <UserList
          user={readCurrentSeasonControlListResult.data?.readCurrentSeason?.userWithSectionsWithoutTargetGroup || []}
          fetching={readCurrentSeasonControlListResult.fetching}
        />
      </div>

      <div className={'w-full max-w-[1400px] mx-auto p-8'}>
        <h3 className={'text-xl font-medium tracking-tight text-gray-900 sm:text-2xl mb-4'}>
          Benutzer mit bestätigten Schichten, die nicht in der Festivalgruppe sind
        </h3>
        <UserList
          user={readCurrentSeasonControlListResult.data?.readCurrentSeason?.userWithSlotsWithoutTargetGroup || []}
          fetching={readCurrentSeasonControlListResult.fetching}
        />
      </div>

      <div className={'mb-8'}> </div>
      {/*<Routes>*/}
      {/*  <Route path={':passID'} element={<PassesList />} />*/}
      {/*  <Route index element={<PassesList />} />*/}
      {/*</Routes>*/}
    </SingleColumn>
  )
}
