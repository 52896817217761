import { LockClosedIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../_helpers";

type PropType = {
  label: string;
  small?: boolean;
  children: React.ReactNode,
  isRequired?: boolean
  isPrivate?: boolean
}

export const Fieldgroup = ({ isPrivate, label, children, small, isRequired }: PropType) => {
  return (
    <div
      className={classNames(
        small ? 'sm:pt-2' : 'sm:pt-5'
      )}
    >
      <div role="group">
        <div className="sm:grid sm:grid-cols-3 sm:gap-2">
          <div>
            <div
              className={classNames(
                "flex font-medium text-gray-900 sm:text-gray-700",
                small ? 'text-xs sm:pt-0.5' : 'text-sm',
                isRequired ? 'font-bold' : ''
              )}
            >
              {isPrivate && (
                <LockClosedIcon className={'w-4 h-4 mr-2 shrink-0'} />
              )}
              {label}
            </div>
          </div>
          <div className="mt-4 sm:col-span-2 sm:mt-0">
            <div className="max-w-lg space-y-4">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
