import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { classNames } from "../../_helpers";
import { Spinner } from "../../_ui";

type PropTypes = {
  query: string
  isInput?: boolean
  setQuery: Dispatch<SetStateAction<string>>
  resultsOpen?: boolean
  fetching?: boolean
  setResultsOpen?: Dispatch<SetStateAction<boolean>>
}

export const SearchInput = ({ query, setQuery, isInput, resultsOpen, setResultsOpen, fetching }: PropTypes) => {
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (!resultsOpen) {
      inputRef.current?.blur();
    }
  }, [resultsOpen])

  return (
    <>
      <div className="flex w-full md:ml-0 z-30">
        <label htmlFor="search-field" className="sr-only">
          Suche nach Benutzern
        </label>
        <div
          className={classNames('relative w-full text-gray-400 focus-within:text-gray-600')}
          onClick={() => setResultsOpen && setResultsOpen(true)}
        >
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center cursor-pointer">
            {fetching ? (
              <Spinner color={'text-gray-600'} />
            ) : (
              <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            )}
          </div>
          <input
            ref={inputRef}
            name="search-field"
            id="search-field"
            value={query}
            onChange={event => setQuery(event.target.value)}
            className={classNames(
              'h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-600 cursor-pointer focus:cursor-text focus-within:text-gray-800 placeholder-gray-300 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0',
              !isInput ? 'hidden md:block' : 'z-10'
            )}
            placeholder="Suche nach Vornamen, Nachnamen, Email"
            autoComplete={'off'}
          />
        </div>
      </div>
      <button className="right-12 flex items-center cursor-pointer z-40 md:pr-16" onClick={() => setResultsOpen && setResultsOpen(false)}>
        <ArrowUturnLeftIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"  />
      </button>
      </>
  )
}
