import React, { useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import { User } from "../../types/graphql/User";

type PropTypes = {
  fetching: boolean
  users: User[]
  skeletonAmount: number
}

export const UserList = ({ fetching, users, skeletonAmount = 3 }: PropTypes) => {
  const [, setSearchParams] = useSearchParams();

  const handleUserOpen = useCallback((userID: string) => {
    setSearchParams(prev => {
      prev.set('modal', `user-${userID}`)
      return prev
    })
  }, [setSearchParams])

  return (
    <div className="inline-block min-w-full py-2 align-middle">
      <div className="shadow-sm ring-1 ring-black ring-opacity-10 rounded">

        <table className="min-w-full border-separate" style={{borderSpacing: 0}}>
          <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 bg-gray-200 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6"
            >
              Nachname
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              Vorname
            </th>
          </tr>
          </thead>

          <tbody className="bg-white">
          {fetching && new Array(skeletonAmount).fill(null).map(() => (
            <tr>
              <td
                className="whitespace-nowrap pointer border-b border-gray-200 bg-gray-50 bg-opacity-50 px-3 py-4 text-sm text-gray-500 sm:table-cell"
              >
                <Skeleton width={100} height={20}/>
              </td>
              <td
                className="whitespace-nowrap pointer border-b border-gray-200 bg-gray-50 bg-opacity-50 px-3 py-4 text-sm text-gray-500 sm:table-cell"
              >
                <Skeleton width={150} height={20}/>
              </td>
            </tr>
          ))}
          {!fetching && users?.map((user) => (
            <tr>
              <td
                onClick={() => handleUserOpen(user.id)}
                className="cursor-pointer whitespace-nowrap pointer border-b border-gray-200 bg-gray-50 bg-opacity-50 px-3 py-4 text-sm text-gray-500 sm:table-cell sm:pl-6"
              >
                {user.surname}
              </td>
              <td
                onClick={() => handleUserOpen(user.id)}
                className="cursor-pointer whitespace-nowrap pointer border-b border-gray-200 bg-gray-50 bg-opacity-50 px-3 py-4 text-sm text-gray-500 sm:table-cell"
              >
                {user.firstName}
              </td>
            </tr>
          ))}
          {!fetching && users?.length === 0 && (
            <tr>
              <td
                colSpan={2}
                className="whitespace-nowrap pointer border-b border-gray-200 bg-gray-50 bg-opacity-50 px-3 py-4 text-sm text-gray-500 sm:table-cell sm:pl-6"
              >
                Keine Ergebnisse gefunden
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
