import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Dispatch, Fragment, SetStateAction } from 'react'
import { useMyself } from "../_features/UserContext";
import { NavigationItem } from "../types/NavigationItem";
import { MenuItem } from "./MenuItem";

type PropTypes = {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export const Sidebar = ({ mobileMenuOpen, setMobileMenuOpen }: PropTypes) => {
  const { canAccess, navigationItems } = useMyself() || {}

  if (!canAccess) {
    return null
  }

  return (
    <>
      <div className="hidden w-28 overflow-y-auto bg-primary-100 md:block">
        <div className="flex w-full flex-col items-center px-2 py-6">
          <div className="flex flex-shrink-0 items-center">
            <img
              className="w-auto"
              src="/logo-rab.png"
              alt="Rock am Beckenrand Logo"
            />
          </div>
          <div className="mt-6 w-full flex-1 space-y-1">
            {navigationItems?.map((item: NavigationItem) => (
              <MenuItem key={item.href} item={item} />
            ))}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20 md:hidden" onClose={() => setMobileMenuOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-primary-100 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center bg-white/50 rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="/logo-rab.png"
                    alt="Rock am Beckenrand"
                  />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1">
                      {navigationItems?.filter(item => !item.permissions || canAccess(item.permissions)).map((item) => (
                        <MenuItem
                          key={item.href}
                          item={item}
                          mobile
                        />
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
