import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useMutation } from 'urql'
import { updateProfileImageMutation } from '../../../_lib/graphql/mutations'
import { UserIcon } from '@heroicons/react/24/outline'
import { useSnackbarPush } from '../../SnackbarContext/SnackbarContext'
import Webcam from 'react-webcam'

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export const EditProfileImage = ({user}) => {
  const { pushSuccess, pushError } = useSnackbarPush()
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [webcamMode, setWebcamMode] = useState(false);
  const [webcamImgSrc, setWebcamImgSrc] = useState(null);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const webcamRef = useRef(null);
  const [mutationState, executeUpdateProfileImage] = useMutation(updateProfileImageMutation)

  const submitProfileImage = () => {
    executeUpdateProfileImage({
      input: {
        userID: user.id,
        file: webcamImgSrc || image
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        setFiles([])
        setImage(null)
        setWebcamImgSrc(null)
        pushSuccess('Profilbild gespeichert')
      }
    })
  }

  const switchCam = React.useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);

  const resetProfileImage = () => {
    setFiles([])
    setImage(null)
    setWebcamImgSrc(null)
  }

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.readAsDataURL(acceptedFiles[0])

        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result)
          }
          else {
            reject(Error("Failed converting to base64"))
          }
        }

      })
      promise.then(result => {
        setImage(result)
      }, err => {
        console.log(err)
      })

      setImage(acceptedFiles[0])
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setWebcamImgSrc(imageSrc);
  }, [webcamRef]);

  const videoConstraints = {
    facingMode: FACING_MODE_USER
  };

  if (!user) {
    return null
  }

  return (
    <section className={'grid gap-2 mb-4 sm:gap-4'}>
      {!webcamMode ? (
        <>
          <aside className={'w-full'}>
            <div className={'max-w-[200px] sm:mt-4 mx-auto rounded-lg overflow-hidden aspect-square border border-4 border-gray-50 ring-1 ring-gray-200'}>
              {(files?.length === 1 || user?.profileImageUrl) ? (
                <img
                  className={'object-cover w-full h-full'}
                  src={files?.length === 1 ? files[0].preview : user.profileImageUrl}
                  alt={'Profilbild'}
                />
              ) : (
                <div className="flex flex-col flex-shrink-0 items-center justify-center h-full w-full bg-gray-200 text-center text-xs text-gray-500 sm:text-base">
                  <UserIcon className={'w-16 h-16'} />
                  <span className={''}>Kein Profilbild</span>
                </div>
              )}
            </div>
          </aside>

          <div className="p-4 bg-gray-100 border border-gray-200 rounded-lg text-gray-500">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <p className={'text-sm'}>Ziehe ein Bild mit der Maus hier rein oder klicke, um eins von deinem Computer auszuwählen</p>
            </div>
          </div>
        </>
      ) : (
        <>
          {webcamImgSrc ? (
            <div className={'max-w-[200px] sm:mt-4 mx-auto rounded-lg overflow-hidden aspect-square border border-4 border-gray-50 ring-1 ring-gray-200'}>
              <img
                className={'object-cover w-full h-full'}
                src={webcamImgSrc}
                alt={'Profilbild'}
              />
            </div>
          ) : (
            <Webcam
              height={600}
              width={600}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              audio={false}
              mirrored={facingMode === FACING_MODE_USER}
              videoConstraints={{
                ...videoConstraints,
                facingMode
              }}
            />
          )}

          {!webcamImgSrc && (
            <div className={'grid grid-cols-2 gap-2'}>
              <button
                disabled={mutationState.fetching}
                onClick={switchCam}
                className={'className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none sm:text-sm disabled:bg-gray-300 disabled:cursor-not-allowed"'}
                // className={'w-full border border-primary rounded text-white block mt-2 px-4 py-1 text-sm text-center hover:bg-primary hover:text-white'}
              >
                Kamera wechseln
              </button>

              <button
                disabled={mutationState.fetching}
                onClick={capture}
                className={'className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none sm:text-sm disabled:bg-gray-300 disabled:cursor-not-allowed"'}
                // className={'w-full border border-primary rounded text-white block mt-2 px-4 py-1 text-sm text-center hover:bg-primary hover:text-white'}
                >
                Jetzt aufnehmen
              </button>
            </div>
          )}
        </>
      )}

      {(files?.length === 1 || webcamImgSrc) && (
        <div className={'grid grid-cols-2 gap-2'}>
          <button
            disabled={mutationState.fetching}
            onClick={resetProfileImage}
            className={'className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none sm:text-sm disabled:bg-gray-300 disabled:cursor-not-allowed"'}
            // className={'w-full border border-primary rounded text-white block mt-2 px-4 py-1 text-sm text-center hover:bg-primary hover:text-white'}
          >
            Verwerfen
          </button>
          <button
            disabled={mutationState.fetching}
            onClick={submitProfileImage}
            className={'className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none sm:text-sm disabled:bg-gray-300 disabled:cursor-not-allowed"'}
            // className={'w-full border border-primary rounded text-white block mt-2 px-4 py-1 text-sm text-center hover:bg-primary hover:text-white'}
          >
            Bild speichern
          </button>
        </div>
      )}

      <div className={'grid grid-cols-1 gap-2'}>
        <button
          disabled={mutationState.fetching}
          onClick={() => setWebcamMode(prev => !prev)}
          className={'className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none sm:text-sm disabled:bg-gray-300 disabled:cursor-not-allowed"'}
          // className={'w-full border border-primary rounded text-white block mt-2 px-4 py-1 text-sm text-center hover:bg-primary hover:text-white'}
        >
          {webcamMode ? 'Abbrechen' : 'Jetzt aufnehmen'}
        </button>
      </div>
    </section>
  );
}
