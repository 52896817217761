import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { APP_ACCEPT_MEMBERSHIPS } from "../../_constants/permissions";
import { classNames } from "../../_helpers";
import { Membership } from "../../types/graphql/Membership";
import { useMyself } from "../UserContext";

type PropTypes = {
  currentMembership?: Membership,
  currentMembershipStatus: string,
  currentMembershipStatusTitle: string,
  updateMembershipStatus: (membershipID: string, status: string) => void,
}

export const MembershipStatus = ({currentMembership, currentMembershipStatus, currentMembershipStatusTitle, updateMembershipStatus }: PropTypes) => {
  const { canAccess } = useMyself() || {}

  return (
    <div className={classNames(
      'flex justify-between items-center border rounded-lg px-3 py-1 text-center text-sm',
      currentMembershipStatus === 'active' ? 'bg-lime-200 ring-inset ring-1 ring-lime-500 text-lime-900' : '',
      currentMembershipStatus === 'requested' ? 'bg-yellow-200 ring-inset ring-1 ring-yellow-300 text-yellow-700' : ''
    )}>
      <span className={'text-xs'}>
        {currentMembershipStatusTitle}
      </span>

      {canAccess && canAccess(APP_ACCEPT_MEMBERSHIPS) && (
        <Menu as="div" className="relative flex items-center text-left">
          <Menu.Button>
            <EllipsisVerticalIcon className={'h-4 w-4'} />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-50 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <div className={'flex items-center p-2 border-b text-gray-700 font-medium'}>
                  <UserGroupIcon className={'w-4 h-4 mr-2'} />
                  {currentMembership?.userGroup?.title || ''}
                </div>
                {currentMembership?.status !== 'active' && (
                  <>
                    <Menu.Item>
                      <div>
                        <button
                          onClick={(e: any) => {
                            // e.stopPropagation()
                            updateMembershipStatus(currentMembership?.id || '', 'confirm')
                          }}
                          className={'text-gray-500 w-full block px-4 py-2 text-sm text-left hover:bg-gray-100'}
                        >
                          Akzeptieren
                        </button>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div>
                        <button
                          onClick={(e: any) => {
                            e.stopPropagation()
                            updateMembershipStatus(currentMembership?.id || '', 'reject')
                          }}
                          className={'text-gray-500 w-full block px-4 py-2 text-sm text-left hover:bg-gray-100'}
                        >
                          Ablehnen
                        </button>
                      </div>
                    </Menu.Item>
                  </>
                )}

                {currentMembership?.status === 'active' && (
                  <>
                    <Menu.Item>
                      <div>
                        <button
                          onClick={(e: any) => {
                            // e.stopPropagation()
                            updateMembershipStatus(currentMembership?.id || '', 'requested')
                          }}
                          className={'text-gray-500 w-full block px-4 py-2 text-sm text-left hover:bg-gray-100'}
                        >
                          Angefragt
                        </button>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div>
                        <button
                          onClick={(e: any) => {
                            e.stopPropagation()
                            updateMembershipStatus(currentMembership?.id || '', 'leave')
                          }}
                          className={'text-gray-500 w-full block px-4 py-2 text-sm text-left hover:bg-gray-100'}
                        >
                          Gruppe verlassen
                        </button>
                      </div>
                    </Menu.Item>
                  </>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  )
}
