import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { Season } from "../../types/graphql/Season";
import { User } from "../../types/graphql/User";
import { Section } from "./components/Section";

type PropTypes = {
  open: boolean,
  onClose: () => void,
  season?: Season
  profile: User
}

export const SectionLeadEditView = ({open, onClose, season, profile}: PropTypes) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white w-full px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-xl sm:p-6">
                <div className={'grid grid-cols-2 gap-2'}>
                  <div className={'col-span-2 flex justify-between'}>
                    <Dialog.Title as="h3" className="mb-3 text-lg text-center font-medium leading-6 text-gray-900">
                      Leitung bearbeiten
                    </Dialog.Title>
                    <button onClick={onClose} className={'mb-3'}>
                      <XMarkIcon className={'w-5 h-5'} />
                    </button>
                  </div>

                  {season?.sections?.map(section => (
                    <Section
                      key={section.id}
                      section={section}
                      profile={profile}
                    />
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
